import React, { useEffect, useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useBusinessCategory = () => {
  const [businessCategoryLoading, setBusinessCategoryLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const getAllBusinesscategory = async () => {
    try {
      setBusinessCategoryLoading(true);
      await BaseClient.get(APIEndpoints.getAllBusinessCategoryList, {
        onSuccess: (res) => {
          if (!Array.isArray(res.data)) return;
          setCategoryList(res.data);
        },
        onFailed: (err) => {
          console.log("ERROR GETTING BUSINESS CATEGORY LIST");
        },
      });
    } finally {
      setBusinessCategoryLoading(false);
    }
  };

  const addBusinessCategory = async (formData, { onSuccess, onFailed }) => {
    try {
      setBusinessCategoryLoading(true);
      await BaseClient.post(APIEndpoints.addBusinessCategory, formData, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setBusinessCategoryLoading(false);
    }
  };

  return {
    getAllBusinesscategory,
    categoryList,
    businessCategoryLoading,
    addBusinessCategory,
  };
};
export default useBusinessCategory;
