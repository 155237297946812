import BaseClient from "../../src/Helpers/BaseClient";
import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";

const useUserReview = () => {
  const [reviewLoading, setReviewLoading] = useState({
    action: "",
    status: false,
  });
  const [userReviewList, setUserReviewList] = useState([]);

  const getAllUserReview = async () => {
    try {
      setReviewLoading({
        action: "get",
        status: true,
      });

      await BaseClient.get(APIEndpoints.getAllReviews, {
        onSuccess: (res) => {
          if (!Array.isArray(res.data)) return;
          setUserReviewList(res.data);
        },
        onFailed: (err) => {
          console.log("ERROR ON FETCHING USER REVIEW LIST");
        },
      });
    } finally {
      setReviewLoading({
        action: "",
        status: false,
      });
    }
  };

  const approveSingleReview = async (payload, { onSuccess, onFailed }) => {
    try {
      setReviewLoading({
        action: "approve",
        status: true,
      });

      await BaseClient.post(APIEndpoints.approveUserReview, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setReviewLoading({
        action: "",
        status: false,
      });
    }
  };

  const deleteSingleReview = async (id, { onSuccess, onFailed }) => {
    try {
      setReviewLoading({
        action: "delete",
        status: true,
      });

      await BaseClient.delete(APIEndpoints.deleteUserReview + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setReviewLoading({
        action: "",
        status: false,
      });
    }
  };

  const editReviewData = async (payload, { onSuccess, onFailed }) => {
    try {
      setReviewLoading({
        action: "edit",
        status: true,
      });

      await BaseClient.post(APIEndpoints.editReviewData, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setReviewLoading({
        action: "",
        status: false,
      });
    }
  };

  return {
    getAllUserReview,
    userReviewList,
    reviewLoading,
    deleteSingleReview,
    approveSingleReview,
    editReviewData,
  };
};

export default useUserReview;
