import React, { useState, useEffect, useContext } from "react";
import Header from "../../Components/Header/Header";
import "./Dashboard.css";
import { AppContext } from "../../Context/AppContext";
import Utils from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  const { usersList, userReviewList } = useContext(AppContext);
  const [lastUserData, setLastUserData] = useState(null);
  const recentCount = 10;

  useEffect(() => {
    if (userReviewList.length == 0) return;
    const lastIndexValue = userReviewList[userReviewList.length - 1];
    setLastUserData(lastIndexValue);
  }, [userReviewList]);

  return (
    <div className="container-fluid background">
      <Header />
      <div className="mains">
        <header className="data">
          <h4 className="dash-head text-center mt-3 mb-3">Dashboard</h4>
        </header>
        <div class="page-content">
          <div class="main-wrapper">
            <div class="row">
              <div class="col-md-6 col-xl-3 mb-3">
                <div class="card stat-widget">
                  <div class="card-body">
                    <h5 class="card-title">New Users</h5>
                    <h2>{usersList != null ? usersList?.length : "0"}</h2>
                    <p>till now</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-3 mb-3">
                <div class="card stat-widget">
                  <div class="card-body">
                    <h5 class="card-title">Reviews</h5>
                    <h2>
                      {userReviewList != null ? userReviewList?.length : "0"}
                    </h2>
                    <p>On Live</p>
                  </div>
                </div>
              </div>
              {/* <div class="col-md-6 col-xl-3 mb-3">
                <div class="card stat-widget">
                  <div class="card-body">
                    <h5 class="card-title">Last Review</h5>
                    <p className="dashboard_protest">
                      {lastUserData?.protest ?? "N/A"}
                    </p>
                    <p className="mb-0">
                      <b>{lastUserData?.user?.fullName ?? "N/A"}</b>
                    </p>
                    <p style={{ fontSize: "13px", marginBottom: "0px" }}>
                      {lastUserData?.user?.email ?? "N/A"}
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div class="col-md-6 col-xl-3  mb-3">
                <div class="card stat-widget">
                  <div class="card-body">
                    <h5 class="card-title">Orders</h5>
                    <h2>87</h2>
                    <p>Orders in waitlist</p>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="mt-3">
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <div class="card table-widget dash_table">
                    <div class="card-body">
                      <h5 class="card-title">Recent Comments</h5>
                      <div class="table-responsive">
                        <table class="table ">
                          <thead>
                            <tr>
                              <th scope="col">Id</th>
                              <th scope="col">Client</th>
                              <th scope="col">Reviewed company</th>
                              <th scope="col" className="text-center">
                                Date
                              </th>
                              <th scope="col">Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {userReviewList != null &&
                              userReviewList.map((review, index) => {
                                if (index < recentCount) {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{review?.user?.fullName ?? "N/A"}</td>
                                      <td>{review?.businessName ?? "N/A"}</td>
                                      <td className="text-center">
                                        {review.creation_Date != null
                                          ? Utils.formatDate(
                                              review.creation_Date
                                            )
                                          : "N/A"}
                                      </td>
                                      <td id="comment">
                                        <p>{review?.protest ?? "N/A"}</p>
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <button
                      className="view_more"
                      onClick={() => navigate("/complaints")}
                    >
                      View more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
