import React, { useContext, useEffect, useState } from "react";
import "../../Pages/Settings/Settings.css";
import toast, { Toaster } from "react-hot-toast";
import { BsTrash3 } from "react-icons/bs";
import { AppContext } from "../../Context/AppContext";
import { PulseLoader } from "react-spinners";
import { Switch } from "antd";

function About() {
  const {
    updateContent,
    pageData,
    createSettings,
    settingsLoading,
    fetchAllPageData,
    publushedStatusUpdate,
  } = useContext(AppContext);

  const [textFields, setTextFields] = useState([]);
  const [aboutPage, setAboutPage] = useState(null);
  const [published, setPublished] = useState(false);

  useEffect(() => {
    const data = pageData.find((x) => x.pageName === "About");
    if (data) {
      setAboutPage(data);
      setTextFields(data?.contents?.aboutParagraphs || []);
      setPublished(data?.published);
    }
  }, [pageData]);

  useEffect(() => {
    if (aboutPage) {
      setAboutHead(aboutPage?.contents?.aboutHead || "");
      setAboutPara1(aboutPage?.contents?.aboutPara1 || "");
      setAboutPara2(aboutPage?.contents?.aboutPara2 || "");
    }
  }, [aboutPage]);

  const [aboutHead, setAboutHead] = useState("");
  const [aboutPara1, setAboutPara1] = useState("");
  const [aboutPara2, setAboutPara2] = useState("");

  const handleChange = (e) => {
    setAboutPara1(e.target.value);
  };

  const handlePara = (e) => {
    setAboutPara2(e.target.value);
  };

  const handleHead = (e) => {
    setAboutHead(e.target.value);
  };

  const autoResize = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const addTextField = () => {
    setTextFields((prevState) => [
      ...prevState,
      { id: prevState.length + 1, value: "" },
    ]);
  };

  const handleTextFieldChange = (id, value) => {
    setTextFields((prevState) =>
      prevState.map((field) => (field.id === id ? { ...field, value } : field))
    );
  };

  const removeTextField = (id) => {
    setTextFields((prevState) => prevState.filter((field) => field.id !== id));
  };

  const handleAbout = async () => {
    const empty = textFields.some((field) => field.value.trim() === "");
    if (empty) {
      toast.error("Please update or remove empty Paragraph field!");
      return;
    }
    if (
      aboutHead.length === 0 ||
      aboutPara1.length === 0 ||
      aboutPara2.length === 0
    ) {
      toast.error("Please Update all Fields!");
      return;
    }
    const payload = {
      pageName: aboutPage?.pageName,
      pageId: aboutPage?._id,
      contents: {
        aboutParagraphs: textFields,
        aboutHead: aboutHead,
        aboutPara1: aboutPara1,
        aboutPara2: aboutPara2,
      },
    };

    await updateContent(payload, {
      onSuccess: async (res) => {
        toast.success("About Content Changed Successfully!");
        await fetchAllPageData();
      },
      onFailed: (err) => {
        toast.error(err?.message || "Something went Wrong!");
        console.error(err);
      },
    });
  };

  const handlePublishedStatus = async () => {
    setPublished(!published);

    await publushedStatusUpdate(aboutPage?._id, {
      onSuccess: async (res) => {
        console.log(res);
        let message =
          published == true
            ? "Page unpublished successfully!"
            : "Page published successfully!";
        toast.success(message);
        await fetchAllPageData();
      },
      onFailed: (err) => {
        let errMsg = err?.errormessage ? err.errormessage : err.message;
        console.log(err);
        toast.error(errMsg);
      },
    });
  };

  return (
    <div className="container" style={{ background: "#fff" }}>
      {!settingsLoading ? (
        <div className="pulished_">
          <label htmlFor="" className="me-2">
            {published === true ? (
              <b> Disable this page? </b>
            ) : (
              <b> Enable this page? </b>
            )}
          </label>
          <Switch checked={published} onChange={handlePublishedStatus} />
        </div>
      ) : (
        <label>Please wait..</label>
      )}
      <div className="row">
        <div className="add_btn mt-2">
          <button className="btn-add" onClick={addTextField}>
            +
          </button>
        </div>
      </div>

      <b>Paragraph</b>
      {textFields != null &&
        Array.isArray(textFields) &&
        textFields.map((field) => (
          <div key={field.id}>
            <textarea
              placeholder="Enter text here"
              className="card-para mt-2 p-3"
              value={field.value}
              onChange={(e) => handleTextFieldChange(field.id, e.target.value)}
            />
            <i className="remove" onClick={() => removeTextField(field.id)}>
              {" "}
              <BsTrash3 />
            </i>
          </div>
        ))}
      <div className="row">
        <div className="add_btn mt-2">
          <button className="btn-add" onClick={addTextField}>
            +
          </button>
        </div>
      </div>
      <div className="card p-3 mt-3 mb-3">
        <b>Other Content</b>
        <div className="row mt-3">
          <div className="col-md-6">
            <input
              className="head-card"
              value={aboutHead}
              name="aboutHead"
              onChange={handleHead}
            />
            <textarea
              className="card-para"
              name="text"
              value={aboutPara1}
              onChange={handleChange}
              onInput={autoResize}
              placeholder="Enter your text here..."
            />
          </div>
          <div className="col-md-6">
            <textarea
              className="card-para"
              value={aboutPara2}
              name="para"
              onChange={handlePara}
              onInput={autoResize}
              placeholder="Enter your text here..."
            />
          </div>
        </div>
        <div className="row"></div>
        <button
          type="button"
          className="update_Btn"
          onClick={handleAbout}
          disabled={settingsLoading}
        >
          {settingsLoading ? <PulseLoader color="#fff" size={10} /> : "Update"}
        </button>
      </div>
    </div>
  );
}

export default About;
