import React, { useState, useEffect, useContext } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import * as Md from "react-icons/md";
import Logo from "../../Assets/logo-home.png";
import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import * as Bi from "react-icons/bi";
import * as Hi from "react-icons/hi";
import "./Header.css";
import { AppContext } from "../../Context/AppContext";
function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { deleteCookieData } = useContext(AppContext);
  const [isUrl, setIsUrl] = useState(null);
  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    setIsUrl(location.pathname);
  }, [location]);

  const handleLogout = async () => {
    await deleteCookieData({
      onLogoutSuccess: () => {
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      },
    });
  };

  return (
    <div className="container-fluid backgrd">
      <div className="container-fluid"></div>
      <div className={`side-bar ${!sidebar ? "" : "hide"}`}>
        <div className="text-center">
          <img src={Logo} alt="" className="logo-svg" />
        </div>
        <div className="text-center head">
          <b>Trusted Payer</b>
        </div>
        <div className="text-center">
          <h6 className="menu mb-3">Menu</h6>
        </div>
        <ul>
          <li className={"links " + (isUrl === "/" ? "active" : "")}>
            <Link to="/" className="nav-links">
              <i>
                <Md.MdOutlineSpaceDashboard />
              </i>
              Dashboard
            </Link>
            <p className={"tooltip_ " + (isUrl === "/" ? "active" : "")}>
              Dashboard
            </p>
          </li>

          <li
            className={
              "links " + (isUrl === "/businessprofile" ? "active" : "")
            }
          >
            <Link to="/businessprofile" className="nav-links">
              <i>
                <Bs.BsBuilding />
              </i>
              Companies
            </Link>
            <span
              className={
                "tooltip_ " + (isUrl === "/businessprofile" ? "active" : "")
              }
            >
              Companies
            </span>
          </li>
          <li
            className={
              "links " + (isUrl === "/businesscategory" ? "active" : "")
            }
          >
            <Link to="/businesscategory" className="nav-links">
              <i>
                <Md.MdOutlineCategory />
              </i>
              Business Categories
            </Link>
            <span
              className={
                "tooltip_ " + (isUrl === "/businesscategory" ? "active" : "")
              }
            >
              Business Categories
            </span>
          </li>
          <li
            className={
              "links " + (isUrl === "/complaint-category" ? "active" : "")
            }
          >
            <Link to="/complaint-category" className="nav-links">
              <i>
                <Ai.AiOutlineMail />
              </i>
              Review Category
            </Link>
            <span
              className={
                "tooltip_ " + (isUrl === "/complaint-category" ? "active" : "")
              }
            >
              Review Category
            </span>
          </li>
          <li className={"links " + (isUrl === "/users" ? "active" : "")}>
            <Link to="/users" className="nav-links">
              <i>
                <Bi.BiUserCircle />
              </i>
              Users
            </Link>
            <span
              className={
                "tooltip_ " +
                (isUrl === "/users"
                  ? "active"
                  : "/viewuser/:id"
                  ? "active"
                  : "")
              }
            >
              Users
            </span>
          </li>
          <li className={"links " + (isUrl === "/complaints" ? "active" : "")}>
            <Link to="/complaints" className="nav-links">
              <i>
                <Ai.AiOutlineMail />
              </i>
              Reviews
            </Link>
            <span
              className={
                "tooltip_ " + (isUrl === "/complaints" ? "active" : "")
              }
            >
              Reviews
            </span>
          </li>
          <li className={"links " + (isUrl === "/settings" ? "active" : "")}>
            <Link to="/settings" className="nav-links">
              <i>
                <Ai.AiFillSetting />
              </i>
              Settings
            </Link>
            <span
              className={"tooltip_ " + (isUrl === "/settings" ? "active" : "")}
            >
              Settings
            </span>
          </li>
        </ul>
        <div className="footered">
          <button className="log" onClick={handleLogout}>
            <Ai.AiOutlinePoweroff />
            <span className="ps-3">Logout</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Header;
