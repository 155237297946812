import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import toast, { Toaster } from "react-hot-toast";
import { PulseLoader } from "react-spinners";
import { AppContext } from "../../Context/AppContext";
import Backbutton from "../../Components/Header/Backbutton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function AddBusinessProfile() {
  const navigate = useNavigate();
  const {
    addBusinessProfile,
    businessProfileLoading,
    categoryList,
    getBusinessProfile,
    countyList,
  } = useContext(AppContext);
  const [profileValues, setProfileValues] = useState({
    businessName: "",
    websitelink: "",
    businesshours: "",
    description: "",
    businessCategory: "",
    address: [
      {
        line1: "",
        line2: "",
        locality: "",
        country: "United Kingdom",
        county: "",
        postalCode: "",
      },
    ],
    image: null,
  });
  const [error, setError] = useState({});
  const [description, setDescription] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    // Business Name
    if (!profileValues.businessName) {
      formIsValid = false;
      errors["businessName"] = "Business Name cannot be empty.";
    }

    // Business Category
    if (!profileValues.businessCategory) {
      formIsValid = false;
      errors["businessCategory"] = "Please select a business category.";
    }

    // Website Link
    if (
      profileValues.websitelink &&
      !/^https?:\/\/[^\s]+$/i.test(profileValues.websitelink)
    ) {
      formIsValid = false;
      errors["websitelink"] = "Please enter a valid URL.";
    }

    // Postal Code
    const ukPostalCodeRegex =
      /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i;
    if (!ukPostalCodeRegex.test(profileValues.address[0].postalCode)) {
      formIsValid = false;
      errors["postalCode"] = "Please enter a valid postal code.";
    }

    // Address Line 1
    if (!profileValues.address[0].line1) {
      formIsValid = false;
      errors["line1"] = "Address Line 1 cannot be empty.";
    }

    setError(errors);
    return formIsValid;
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const formData = new FormData();
      formData.append("businessName", profileValues.businessName);
      formData.append("description", description);
      formData.append("websitelink", profileValues.websitelink);
      formData.append("businesshours", profileValues.businesshours);
      formData.append("businessCategory", profileValues.businessCategory);
      formData.append("address", JSON.stringify(profileValues.address));

      if (profileValues.image) {
        formData.append("image", profileValues.image);
      }

      await addBusinessProfile(formData, {
        onSuccess: async () => {
          toast.success("Added Successfully!", {
            style: {
              backgroundColor: "#fff",
              padding: "16px",
              color: "#252525",
            },
          });
          await getBusinessProfile();
          setTimeout(() => {
            setProfileValues({
              businessName: "",
              websitelink: "",
              businesshours: "",
              description: "",
              businessCategory: "",
              address: [
                {
                  line1: "",
                  line2: "",
                  locality: "",
                  country: "United Kingdom",
                  county: "",
                  postalCode: "",
                },
              ],
              image: null,
            });
            setDescription("");
            navigate("/businessprofile");
          }, 1000);
        },
        onFailed: (e) => console.log("failed", e),
      });
    } else {
      toast.error("Please fill all required fields correctly.");
    }
  };

  return (
    <div className="container-fluid background">
      <Header />
      <div className="mains">
        <Toaster position="top-center" />
        <div className="edit-title data mb-3">
          <div className="start-edit">
            <Backbutton />
          </div>
          <div className="center-edit text-center">
            <h4 className="dash-head text-center">Add Business Profile</h4>
          </div>
        </div>
        <div className="section add-category">
          <div className="card review_card add_business_card">
            <form onSubmit={formSubmit}>
              <div className="row">
                <div className="mb-3 col-lg-6 col-sm-12">
                  <label htmlFor="businessName" className="form-label">
                    Company Name
                  </label>
                  <span className="required_">*</span>
                  <input
                    type="text"
                    className="form-control"
                    id="businessName"
                    name="businessName"
                    placeholder="Enter Company Name"
                    value={profileValues.businessName}
                    onChange={handleChange}
                  />
                  <span className="text-danger">{error.businessName}</span>
                </div>
                <div className="mb-3 col-lg-6 col-sm-12">
                  <label htmlFor="businessCategory" className="form-label">
                    Business Category
                  </label>
                  <span className="required_">*</span>
                  <select
                    className="form-select"
                    name="businessCategory"
                    value={profileValues.businessCategory}
                    onChange={handleChange}
                  >
                    <option value="" selected disabled hidden>
                      Select a Category
                    </option>
                    {categoryList &&
                      categoryList.map((category, key) => (
                        <option value={category._id} key={key}>
                          {category?.businessCategoryName ?? "N/A"}
                        </option>
                      ))}
                  </select>
                  <span className="text-danger">{error.businessCategory}</span>
                </div>
              </div>

              <div className="row">
                <div className="mb-3 col-lg-4 col-sm-12">
                  <label htmlFor="websitelink" className="form-label">
                    Website Link
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter the Company's site Link"
                    id="websitelink"
                    value={profileValues.websitelink}
                    name="websitelink"
                    onChange={handleChange}
                  />
                  <span className="text-danger">{error.websitelink}</span>
                </div>
                <div className="mb-3 col-lg-4 col-sm-12">
                  <label htmlFor="businesshours" className="form-label">
                    Working Hours<span className="required_">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Business Hours"
                    id="businesshours"
                    value={profileValues.businesshours}
                    name="businesshours"
                    onChange={handleChange}
                    pattern="^[0-9]*$"
                    title="Please enter only numeric values."
                  />
                </div>
                <div className="mb-3 col-lg-4 col-sm-12">
                  <label htmlFor="profileImage" className="form-label">
                    Profile Image
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="profileImage"
                    name="image"
                    onChange={(e) =>
                      setProfileValues((prev) => ({
                        ...prev,
                        image: e.target.files[0],
                      }))
                    }
                  />
                </div>
              </div>

              <div className="row">
                <label className="form-label">
                  <b>Address</b>
                </label>
                <div className="mb-3 col-lg-6 col-sm-12">
                  <label>Line 1</label>
                  <span className="required_">*</span>
                  <input
                    type="text"
                    className="form-control"
                    name="line1"
                    value={profileValues.address[0].line1}
                    onChange={(e) =>
                      setProfileValues((prev) => ({
                        ...prev,
                        address: [
                          { ...prev.address[0], line1: e.target.value },
                        ],
                      }))
                    }
                  />
                  <span className="text-danger">{error.line1}</span>
                </div>
                <div className="mb-3 col-lg-6 col-sm-12">
                  <label>Line 2</label>
                  <input
                    type="text"
                    className="form-control"
                    name="line2"
                    value={profileValues.address[0].line2}
                    onChange={(e) =>
                      setProfileValues((prev) => ({
                        ...prev,
                        address: [
                          { ...prev.address[0], line2: e.target.value },
                        ],
                      }))
                    }
                  />
                </div>
                <div className="mb-3 col-lg-6 col-sm-12">
                  <label>Locality</label>
                  <input
                    type="text"
                    className="form-control"
                    name="locality"
                    value={profileValues.address[0].locality}
                    onChange={(e) =>
                      setProfileValues((prev) => ({
                        ...prev,
                        address: [
                          { ...prev.address[0], locality: e.target.value },
                        ],
                      }))
                    }
                  />
                </div>
                <div className="mb-3 col-lg-6 col-sm-12">
                  <label>Country</label>
                  <input
                    type="text"
                    className="form-control"
                    name="country"
                    value="United Kingdom"
                    disabled
                  />
                </div>
                <div className="mb-3 col-lg-6 col-sm-12">
                  <label>County</label>
                  <span className="required_">*</span>
                  <select
                    name="county"
                    className="form-select form-control"
                    onChange={(e) =>
                      setProfileValues((prev) => ({
                        ...prev,
                        address: [
                          { ...prev.address[0], county: e.target.value },
                        ],
                      }))
                    }
                  >
                    <option value="" disabled selected>
                      Choose a County
                    </option>
                    {countyList &&
                      countyList.map((list, index) => (
                        <option value={list?.name} key={index}>
                          {list?.name ?? "N/A"}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3 col-lg-6 col-sm-12">
                  <label>Postcode</label>
                  <span className="required_">*</span>
                  <input
                    type="text"
                    className="form-control postal_code"
                    value={profileValues.address[0].postalCode}
                    name="postalCode"
                    onChange={(e) => {
                      const updatedValue = e.target.value.toUpperCase();
                      setProfileValues((prev) => ({
                        ...prev,
                        address: [
                          {
                            ...prev.address[0],
                            postalCode: updatedValue,
                          },
                        ],
                      }));
                    }}
                  />
                  <span className="text-danger">{error.postalCode}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-label">Description</div>
                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                />
              </div>
              <div className="text-center mt-3">
                <button type="submit" className="btn btn-danger">
                  {businessProfileLoading ? (
                    <PulseLoader color="#fff" size={10} />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBusinessProfile;
