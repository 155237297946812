import React, { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProtectAuthRoute, ProtectRoute } from "./Routes/Protector";
import AppRoutes from "./Routes/routes";
import "./App.css";
import { AppContext } from "./Context/AppContext";
import Loader from "./Components/loader/Loader";
import ErrorComponent from "./Components/Error/Error";

function App() {
  const { pageLoading, cookieError } = useContext(AppContext);

  // React.useEffect(() => {
  //   console.log("Pageloading", pageLoading);
  //   console.log("cookieError", cookieError);
  // }, [pageLoading, cookieError]);

  return (
    <>
      {pageLoading == false && cookieError == null ? (
        <BrowserRouter>
          <Routes>
            {AppRoutes.map((routes, key) => {
              return !routes.isProtected && !routes.isProtectedAuth ? (
                <Route
                  key={key}
                  path={routes.path}
                  element={routes.element}
                  exact
                />
              ) : !routes.isProtected && routes.isProtectedAuth ? (
                <Route
                  key={key}
                  path={routes.path}
                  element={
                    <ProtectAuthRoute>{routes.element}</ProtectAuthRoute>
                  }
                  exact
                ></Route>
              ) : (
                <Route
                  key={key}
                  path={routes.path}
                  element={<ProtectRoute>{routes.element}</ProtectRoute>}
                  exact
                ></Route>
              );
            })}
          </Routes>
        </BrowserRouter>
      ) : pageLoading == true && cookieError == null ? (
        <Loader />
      ) : (
        <ErrorComponent
          errorTitle={"Unexpected Error"}
          errorMsg={cookieError}
        />
      )}
    </>
  );
}

export default App;
