import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useSettings = () => {
  const [settingsLoading, setSettingsLoading] = useState(false);
  const [pageData, setPageData] = useState(null);
  const createSettings = async (payload, { onSuccess, onFailed }) => {
    try {
      setSettingsLoading(true);

      await BaseClient.post(APIEndpoints.createSettings, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setSettingsLoading(false);
    }
  };
  const updateContent = async (payload, { onSuccess, onFailed }) => {
    try {
      setSettingsLoading(true);

      await BaseClient.put(APIEndpoints.updateContent, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setSettingsLoading(false);
    }
  };
  const fetchAllPageData = async () => {
    try {
      setSettingsLoading(true);
      await BaseClient.get(APIEndpoints.getAllPageData, {
        onSuccess: (res) => {
          setPageData(res.data);
        },
        onFailed: (err) => {
          console.log("ERROR ON GET BUSINESS PROFILE");
        },
      });
    } finally {
      setSettingsLoading(false);
    }
  };

  const publushedStatusUpdate = async (id, { onSuccess, onFailed }) => {
    try {
      setSettingsLoading(true);
      await BaseClient.put(
        APIEndpoints.publishedStatus + `/${id}`,
        {},
        {
          onSuccess: onSuccess,
          onFailed: onFailed,
        }
      );
    } finally {
      setSettingsLoading(false);
    }
  };
  return {
    setSettingsLoading,
    createSettings,
    settingsLoading,
    fetchAllPageData,
    pageData,
    updateContent,
    publushedStatusUpdate,
  };
};
export default useSettings;
