import React, { Fragment, useContext, useEffect, useState } from "react";
import { BsTrash3 } from "react-icons/bs";
import { AppContext } from "../../Context/AppContext";
import toast from "react-hot-toast";
import { PulseLoader } from "react-spinners";
import { Switch } from "antd";

function FaqSection() {
  const {
    updateContent,
    pageData,
    settingsLoading,
    fetchAllPageData,
    publushedStatusUpdate,
  } = useContext(AppContext);
  const [questions, setQuestions] = useState([]);
  const [pageDetails, setPageDetails] = useState(null);
  const [info, setInfo] = useState({
    email: "",
    address: "",
    phone: "",
  });
  const [published, setPublished] = useState(false);
  const [homeInfo, setHomeInfo] = useState(null);

  useEffect(() => {
    const faqData = pageData.find((page) => page.pageName === "Contact");

    if (faqData) {
      setQuestions(faqData.contents.questions || []);
      setPageDetails(faqData);
      setInfo({
        email: faqData.contents.info.email,
        address: faqData.contents.info.address,
        phone: faqData.contents.info.phone,
      });

      setHomeInfo(faqData);
      setPublished(faqData?.published);
    }
  }, [pageData]);

  console.log("published", published);

  const handleTextFieldChange = (id, fieldName, value) => {
    setQuestions((prevState) =>
      prevState.map((field) =>
        field.id === id ? { ...field, [fieldName]: value } : field
      )
    );
  };

  const removeTextField = (id) => {
    setQuestions((prevState) => prevState.filter((field) => field.id !== id));
  };

  const addTextField = () => {
    setQuestions((prevState) => [
      ...prevState,
      { id: prevState.length + 1, question: "", answer: "" },
    ]);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleFAQ = async () => {
    const empty = questions.some((field) => field.question.trim() === "");
    if (empty) {
      toast.error("Please update or remove empty question fields.");
      return;
    }
    if (
      info.address.length === 0 ||
      info.email.length === 0 ||
      info.phone.length === 0
    ) {
      toast.error("Please Update Contact Info!");
    }
    const payload = {
      pageName: pageDetails?.pageName,
      pageId: pageDetails?._id,
      contents: {
        questions: questions,
        info: info,
      },
    };

    await updateContent(payload, {
      onSuccess: async (res) => {
        toast.success("Successfully Updated FAQ!", res);
        await fetchAllPageData();
      },
      onFailed: (err) => {
        toast.error("Something went Wrong!");
        console.error(err);
      },
    });
  };

  const handlePublishedStatus = async () => {
    setPublished(!published);

    await publushedStatusUpdate(homeInfo?._id, {
      onSuccess: async (res) => {
        console.log(res);
        let message =
          published == true
            ? "Page unpublished successfully!"
            : "Page published successfully!";
        toast.success(message);
        await fetchAllPageData();
      },
      onFailed: (err) => {
        let errMsg = err?.errormessage ? err.errormessage : err.message;
        console.log(err);
        toast.error(errMsg);
      },
    });
  };

  return (
    <Fragment>
      <div className="container" style={{ background: "#fff" }}>
        {!settingsLoading ? (
          <div className="pulished_">
            <label htmlFor="" className="me-2">
              {published === true ? <b> Disable this page? </b> : <b> Enable this page? </b>}
            </label>
            <Switch checked={published} onChange={handlePublishedStatus} />
          </div>
        ) : (
          <label>Please wait...</label>
        )}
        <div className="row">
          <div className="add_btn mt-3">
            <button className="btn-add" onClick={addTextField}>
              +
            </button>
          </div>

          <div className="col-md-12">
            <h6>FAQ Questions and Answers</h6>
            {questions.map((field, index) => (
              <div key={field.id} className="card p-3 mb-2">
                <small>
                  {" "}
                  <b>Question {index + 1}</b>
                </small>
                <input
                  type="text"
                  name="question"
                  value={field.question}
                  className="p-2 que_imn"
                  placeholder="Enter question here"
                  onChange={(e) =>
                    handleTextFieldChange(field.id, "question", e.target.value)
                  }
                />
                <textarea
                  placeholder="Enter answer here"
                  className="card-ans mt-2 p-3"
                  name="answer"
                  value={field.answer}
                  onChange={(e) =>
                    handleTextFieldChange(field.id, "answer", e.target.value)
                  }
                />
                <i
                  className="dele-qu mt-2"
                  onClick={() => removeTextField(field.id)}
                >
                  {" "}
                  <BsTrash3 />
                </i>
              </div>
            ))}
          </div>
          <div className="col-md-12 mt-3 mb-3">
            <div className="card p-3 mb-3">
              <div className="row">
                <small>Contact Info</small>
                <div className="col-md-6 col-sm-12">
                  <small>Email</small>
                  <br />
                  <input
                    type="email"
                    className="que_imn"
                    onChange={handleChange}
                    name="email"
                    value={info.email}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <small>Phone</small>
                  <br />
                  <input
                    type="tel"
                    onChange={handleChange}
                    className="que_imn"
                    name="phone"
                    value={info.phone}
                  />
                </div>
                <div className="col-md-12 col-sm-12">
                  <small>Address</small>
                  <br />
                  <textarea
                    placeholder="Enter answer here"
                    className="card-add mt-2 p-3"
                    name="address"
                    onChange={handleChange}
                    value={info.address}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-3">
            <button
              type="button"
              className="update_Btn"
              onClick={handleFAQ}
              disabled={settingsLoading}
            >
              {settingsLoading ? (
                <PulseLoader color="#fff" size={10} />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FaqSection;
