import BaseClient from "../../src/Helpers/BaseClient";
import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";

const useUser = () => {
  const [userListLoading, setUserListLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [banLoading, setBanLoading] = useState(false);

  const getAllUsers = async () => {
    try {
      setUserListLoading(true);

      await BaseClient.get(APIEndpoints.getAllUsers, {
        onSuccess: (res) => {
          if (!Array.isArray(res.data)) return;
          setUsersList(res.data);
        },
        onFailed: (err) => {
          console.log("ERROR ON FETCHING USERS LIST");
        },
      });
    } finally {
      setUserListLoading(false);
    }
  };

  const banSingleUser = async (payload, { onSuccess, onFailed }) => {
    try {
      setBanLoading(true);

      await BaseClient.post(APIEndpoints.banSingleUser, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setBanLoading(false);
    }
  };

  const unBanSingleUser = async (payload, { onSuccess, onFailed }) => {
    try {
      setBanLoading(true);

      await BaseClient.post(APIEndpoints.unBanSingleUser, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setBanLoading(false);
    }
  };

  const editUserProfile = async (payload, { onSuccess, onFailed }) => {
    try {
      setUserListLoading(true);

      await BaseClient.post(APIEndpoints.updateUserDetails, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setUserListLoading(false);
    }
  };
  const deleteUser = async (id, { onSuccess, onFailed }) => {
    try {
      setUserListLoading(true);

      await BaseClient.delete(APIEndpoints.deleteUser + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setUserListLoading(false);
    }
  };
  return {
    getAllUsers,
    usersList,
    userListLoading,
    banSingleUser,
    unBanSingleUser,
    banLoading,
    deleteUser,
    editUserProfile,
  };
};

export default useUser;
