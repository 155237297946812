import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AppContext } from "../Context/AppContext";

export const ProtectRoute = ({ children }) => {
  const { cookieData } = useContext(AppContext);
  console.log(cookieData, "cookie");
  if (cookieData == null || cookieData == "null") {
    return <Navigate to="/login" />;
  }

  return children;
};

export const ProtectAuthRoute = ({ children }) => {
  const { cookieData } = useContext(AppContext);
  return !cookieData ? children : <Navigate to="/" />;
};
