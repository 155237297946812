import React, { useEffect, useState, createContext } from "react";
import useComplaintCategory from "../Hooks/useComplaintCategory";
import useBusinessProfile from "../Hooks/useBusinessProfile";
import useAuth from "../Hooks/useAuth";
import useBusinessCategory from "../Hooks/useBusinessCategory";
import useUser from "../Hooks/useUser";
import useUserReview from "../Hooks/useUserReview";
import useSettings from "../Hooks/useSettings";
export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const {
    adminLogin,
    authLoading,
    getCookieData,
    deleteCookieData,
    setCookieData,
    cookieData,
    cookieError,
    setCookieError,
    pageLoading,
  } = useAuth();

  const {
    getAllUsers,
    usersList,
    userListLoading,
    banSingleUser,
    unBanSingleUser,
    banLoading,
    deleteUser,
    editUserProfile,
  } = useUser();

  const {
    getAllUserReview,
    userReviewList,
    reviewLoading,
    deleteSingleReview,
    approveSingleReview,
    editReviewData,
  } = useUserReview();

  const {
    getComplaintCategory,
    addComplaintCategory,
    complaintCategoryLoading,
    complaintCategoryList,
    categoryLoading,
    deleteComplaintCategory,
  } = useComplaintCategory();

  const {
    addBusinessProfile,
    editBusinessProfile,
    businessProfileLoading,
    profileList,
    getBusinessProfile,
    deleteBusinessloading,
    deleteBusinessProfile,
    getAllCountyLists,
    deleteProfile,
    countyList,
    setBusinessProfileLoading,
  } = useBusinessProfile();
  const {
    setSettingsLoading,
    createSettings,
    settingsLoading,
    fetchAllPageData,
    pageData,
    updateContent,
    publushedStatusUpdate,
  } = useSettings();
  const {
    getAllBusinesscategory,
    categoryList,
    businessCategoryLoading,
    addBusinessCategory,
  } = useBusinessCategory();

  useEffect(() => {
    getCookieData();
  }, []);

  useEffect(() => {
    if (cookieData != null) {
      getComplaintCategory();
      getBusinessProfile();
      getAllBusinesscategory();
      getAllUserReview();
      getAllUsers();
      getAllCountyLists();
      fetchAllPageData();
    }
  }, [cookieData]);

  return (
    <AppContext.Provider
      value={{
        adminLogin,
        authLoading,
        getCookieData,
        getAllUserReview,
        userReviewList,
        reviewLoading,
        deleteCookieData,
        pageData,
        setCookieData,
        cookieData,
        cookieError,
        pageLoading,
        getComplaintCategory,
        complaintCategoryLoading,
        addComplaintCategory,
        complaintCategoryList,
        getBusinessProfile,
        editBusinessProfile,
        addBusinessProfile,
        deleteBusinessloading,
        deleteBusinessProfile,
        businessProfileLoading,
        setBusinessProfileLoading,
        profileList,
        getAllBusinesscategory,
        categoryList,
        businessCategoryLoading,
        usersList,
        userListLoading,
        addBusinessCategory,
        categoryLoading,
        getAllCountyLists,
        countyList,
        deleteSingleReview,
        approveSingleReview,
        banSingleUser,
        unBanSingleUser,
        banLoading,
        deleteUser,
        getAllUsers,
        editReviewData,
        deleteProfile,
        editUserProfile,
        deleteComplaintCategory,
        setSettingsLoading,
        createSettings,
        setCookieError,
        settingsLoading,
        updateContent,
        fetchAllPageData,
        publushedStatusUpdate,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
