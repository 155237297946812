import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./BusinessProfile.css";
import Header from "../../Components/Header/Header";
import image from "../../Assets/portrait-successful-handsome-executive-businessman-smart-casual-wear-looking-camera-smiling.jpg";
import * as Ci from "react-icons/ci";
import * as Bs from "react-icons/bs";
import { AppContext } from "../../Context/AppContext";
import Backbutton from "../../Components/Header/Backbutton";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";

function BusinessProfile() {
  const navigate = useNavigate();
  const { profileList, getBusinessProfile, deleteProfile } =
    useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = profileList.slice(indexOfFirstItem, indexOfLastItem);

  const deleteBusinessProfile = async (id) => {
    if (!id) {
      console.error("Something Went Wrong");
      return;
    }
    await deleteProfile(id, {
      onSuccess: async (res) => {
        toast.success("Business Profile Deleted Successfully!");

        await getBusinessProfile();
        setTimeout(() => {});
      },
      onFailed: (err) => {
        toast.error("Something went Wrong!");
        console.error(err);
      },
    });
  };

  return (
    <div className="container-fluid background">
      <Toaster position="top-center" reverseOrder={false} />
      <Header />
      <div className="mains">
        <div class="table-title data">
          <div class="flex-cont mb-3">
            <div class="flex-start m-1">
              <h4 class="dash-head text-center">List of Business Profiles</h4>
            </div>

            <div class="flex-end">
              <button
                type="button"
                className="btn btn-add"
                onClick={() => navigate("/addbusinessprofile")}
              >
                Add
              </button>
            </div>
          </div>
        </div>

        <section className="add-category">
          <div className="card dash_table p-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" className="text-left">
                    #
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">Category</th>
                  <th scope="col">description</th>
                  <th scope="col" className="text-center">
                    Image
                  </th>
                  <th scope="col" className="text-center">
                    Actions
                  </th>
                  <th scope="col" className="text-center">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems != null &&
                  currentItems.map((profile, index) => {
                    return (
                      <tr>
                        <th key={index}>{index + 1}</th>
                        <td>{profile?.businessName ?? "N/A"}</td>
                        <td>
                          {profile?.businessCategory?.businessCategoryName ??
                            "N/A"}
                        </td>
                        <td className="profile_description">
                          <p>
                            {profile?.description != null ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: profile?.description,
                                }}
                              />
                            ) : (
                              "N/A"
                            )}
                          </p>
                        </td>
                        <td>
                          <div className="profile_img">
                            <img
                              src={
                                profile.profilePic != null
                                  ? process.env.REACT_APP_AWS_IMAGE_URL +
                                    "businessprofilepics/" +
                                    profile.profilePic
                                  : "Image not available"
                              }
                            />
                          </div>
                        </td>
                        <td className="action_">
                          <button
                            className="action_btn"
                            onClick={() =>
                              navigate(`/editbusinessprofile/${profile._id}`)
                            }
                          >
                            <i>
                              <Ci.CiEdit />
                            </i>
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            className="action_btn text-center"
                            onClick={() => deleteBusinessProfile(profile._id)}
                          >
                            <i>
                              <Bs.BsTrash />
                            </i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              onPageChange={handlePageChange}
              pageCount={Math.ceil(profileList.length / itemsPerPage)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              containerClassName={"pagination"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              disabledClassName={"disabled"}
              activeClassName={"paging__link--active"}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default BusinessProfile;
