import React from "react";
import "./Loader.css";
import BounceLoader from "react-spinners/BounceLoader";

function Loader() {
  // const lottieOption = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: loadingData,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice",
  //     },
  //   };
  return (
    <>
      <div className="loader">
        <div className="loader-lottie">
          <BounceLoader color="#e94d56" />
        </div>
      </div>
    </>
  );
}

export default Loader;
