import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import "./Complaints.css";
import Profile from "../../Assets/portrait-successful-handsome-executive-businessman-smart-casual-wear-looking-camera-smiling.jpg";
import * as Ai from "react-icons/ai";
import * as Bi from "react-icons/bi";
import * as Fi from "react-icons/fi";
import * as Bs from "react-icons/bs";
import * as Md from "react-icons/md";
import * as Ri from "react-icons/ri";
import { useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";

function ViewComplaints() {
  const { id } = useParams();
  const { userReviewList, profileList } = useContext(AppContext);
  const [reviewData, setReviewData] = useState(null);

  useEffect(() => {
    getReviewDataById();
  }, [userReviewList]);

  const getReviewDataById = () => {
    if (!id || !userReviewList) return;
    const data = userReviewList.find((x) => x?._id == id);
    setReviewData(data);
  };

  // console.log(reviewData);

  return (
    <div>
      <div className="container-fluid background">
        <Header />
        <div className="mains">
          <h4 className="dash-head data">Review Details</h4>
          <div className="col-lg-9 col-xl-10 col-sm-12">
            <div className="card review-card p-2">
              <div className="verified_customer_section">
                <div className="image_review">
                  <div className="customer_name_review_status">
                    <p className="comp-name">Satellite communications</p>
                    <p className="comp_category">Network</p>
                    <div className="company-info_ text-center"></div>
                  </div>
                </div>

                <div className="cmnt_wrapper mt-2">
                  <div className="d-flex">
                    <div className="customer_name">Jagadeesh s Binu</div>
                    <p className="customer_mail">Jagadedh@gmail.com</p>
                  </div>
                  <p className="user_connection"></p>
                  <p className="comp-des">
                    Quality of service need to be improved.
                  </p>
                </div>
                <div className="row detail">
                  <div className="col-auto">
                    <p id="review_info_">
                      <Ai.AiFillCalendar /> <span>22-03-2023</span>
                    </p>
                  </div>

                  <div className="col-auto">
                    <p id="review_info_">
                      <Ai.AiFillLike /> <span>10</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 className="dash-head mt-3">Older Reviews </h4>
          <div className="row">
            <div className="col-lg-6 col-xl-6 col-sm-12">
              <div className="card review-card p-2">
                <div className="verified_customer_section">
                  <div className="image_review">
                    <div className="customer_image">
                      <img
                        src={Profile}
                        alt="customer"
                        className="rounded-img"
                      />
                    </div>

                    <div className="customer_name_review_status">
                      <p className="comp-name">Satellite communications</p>
                      <p className="comp_category">Network</p>
                      {/* <div className="customer-review">
                      <button className="card_info_btn">
                        <Bs.BsTelephoneFill />
                      </button>
                      <button className="card_info_btn">
                        <Md.MdEmail />
                      </button>
                      <button className="card_info_btn">
                        <Bi.BiGlobe />
                      </button>
                    </div> */}

                      <div className="company-info_ text-center"></div>
                    </div>
                  </div>

                  <div className="cmnt_wrapper mt-2">
                    <div className="d-flex">
                      <div className="customer_name">Jagadeesh s Binu</div>
                      <p className="customer_mail">Jagadedh@gmail.com</p>
                    </div>
                    <p className="user_connection"></p>
                    <p className="comp-des">
                      Quality of service need to be improved.
                    </p>
                  </div>
                  <div className="row detail">
                    <div className="col-auto">
                      <p id="review_info_">
                        <Ai.AiFillCalendar /> <span>22-03-2023</span>
                      </p>
                    </div>

                    <div className="col-auto">
                      <p id="review_info_">
                        <Ai.AiFillLike /> <span>10</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 col-sm-12">
              <div className="card review-card p-2">
                <div className="verified_customer_section">
                  <div className="image_review">
                    <div className="customer_image">
                      <img
                        src={Profile}
                        alt="customer"
                        className="rounded-img"
                      />
                    </div>

                    <div className="customer_name_review_status">
                      <p className="comp-name">Satellite communications</p>
                      <p className="comp_category">Network</p>
                      {/* <div className="customer-review">
                      <button className="card_info_btn">
                        <Bs.BsTelephoneFill />
                      </button>
                      <button className="card_info_btn">
                        <Md.MdEmail />
                      </button>
                      <button className="card_info_btn">
                        <Bi.BiGlobe />
                      </button>
                    </div> */}

                      <div className="company-info_ text-center"></div>
                    </div>
                  </div>

                  <div className="cmnt_wrapper mt-2">
                    <div className="d-flex">
                      <div className="customer_name">Jagadeesh s Binu</div>
                      <p className="customer_mail">Jagadedh@gmail.com</p>
                    </div>
                    <p className="user_connection"></p>
                    <p className="comp-des">
                      Quality of service need to be improved.
                    </p>
                  </div>
                  <div className="row detail">
                    <div className="col-auto">
                      <p id="review_info_">
                        <Ai.AiFillCalendar /> <span>22-03-2023</span>
                      </p>
                    </div>

                    <div className="col-auto">
                      <p id="review_info_">
                        <Ai.AiFillLike /> <span>10</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewComplaints;
