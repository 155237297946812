export default class Utils {
  static formatDate(value) {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Create a normal date string
    const normalDate = `${day}-${month}-${year}`;

    return normalDate;
  }
}
