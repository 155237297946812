import React from "react";
import Header from "../../Components/Header/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import toast, { Toaster } from "react-hot-toast";
import "react-tabs/style/react-tabs.css";
import "../../Pages/Settings/Settings.css";
import Home from "./Home";
import About from "./About";
import FaqSection from "./FaqSection";


function Settings() {
  return (
    <div className="container-fluid">
      <Toaster position="top-center" reverseOrder={false} />
      <Header />
      <div className="container">
        <div className="mains">
          <Tabs>
            <TabList>
              <Tab>Home</Tab>
              <Tab>About</Tab>
              <Tab>FAQ</Tab>
            </TabList>

            <TabPanel>
              <Home />
            </TabPanel>
            <TabPanel>
              <About />
            </TabPanel>
            <TabPanel>
              <FaqSection />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Settings;
