import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import logo from "../../Assets/logo-home.png";
import useAuth from "../../Hooks/useAuth";
import toast, { Toaster } from "react-hot-toast";
import CustomErrors from "../../Constants/Errors";
import { PulseLoader } from "react-spinners";
import * as Bs from "react-icons/bs";

function Login() {
  const navigate = useNavigate();
  const { adminLogin, authLoading } = useAuth();
  const [payload, setPayload] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e;

    return setPayload((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (payload.email.length == 0 || payload.password.length == 0) {
      setError(true);
      return;
    }

    await adminLogin(payload, {
      onSuccess: (res) => {
        toast.success("welcome Admin!");
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      },
      onFailed: (err) => {
        console.log(err);
        switch (err.errorCode) {
          case "UDE":
            toast.error(CustomErrors.USER_NOT_FOUND);
            break;
          case "INR":
            toast.error(CustomErrors.INCORRECT_CREDENTIALS);
            break;
          default:
            toast.error(err.message);
            break;
        }
      },
    });
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-4">
            <div class="card login-box-container">
              <div class="card-body py-4">
                <div class="authent-logo text-center">
                  <img src={logo} alt="" className="image" />
                </div>
                <div class="text-center">
                  <h4 className="login-title">Welcome to Trusted Payer</h4>
                  <p className="login_sub">Please Sign-in to your account.</p>
                </div>

                <form onSubmit={(e) => handleLogin(e)}>
                  <div class="mb-3">
                    <div class="form-floating">
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        id="floatingInput"
                        placeholder="Your Email Address"
                        value={payload.email}
                        onChange={(e) => handleChange(e.target)}
                      />
                      <label className="login-label" for="floatingInput">
                        Email address
                      </label>
                    </div>
                    {error && payload.email.length == 0 && (
                      <span className="error_">Email is Required!</span>
                    )}
                  </div>
                  <div class="mb-3">
                    <div class="form-floating">
                      <input
                        type={!showPassword ? "password" : "text"}
                        name="password"
                        class="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        value={payload.password}
                        onChange={(e) => handleChange(e.target)}
                      />
                      <button
                        type="button"
                        className="visible_password"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? <Bs.BsEye /> : <Bs.BsEyeSlash />}
                      </button>
                      <label className="login-label" for="floatingPassword">
                        Password
                      </label>
                    </div>
                    {error && payload.password.length == 0 && (
                      <span className="error_">Password is Required!</span>
                    )}
                  </div>
                  {/* <div class="mb-3 form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                    />
                    <label class="form-check-label" for="exampleCheck1">
                      Check me out
                    </label>
                  </div> */}
                  <div class="d-grid">
                    <button type="submit" class="review-btn m-b-xs">
                      {authLoading ? (
                        <PulseLoader color="#fff" size={10} />
                      ) : (
                        "login"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
