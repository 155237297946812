import React, { useState, useEffect, useContext } from "react";
import Header from "../../Components/Header/Header";
import * as Ai from "react-icons/ai";
import * as Md from "react-icons/md";
import { AppContext } from "../../Context/AppContext";
import Utils from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import ReviewModal from "./ReviewModal";

function Complaints() {
  const navigate = useNavigate();
  const { userReviewList, profileList } = useContext(AppContext);
  const [viewModal, setViewModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handleOpenModal = (review) => {
    setViewModal(true);
    setSelectedReview(review);
  };
  const [pageOffset, setPageOffset] = useState(0);

  const sortedReviewList = userReviewList.sort(
    (a, b) => new Date(b.creation_Date) - new Date(a.creation_Date)
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);

    const newOffset =
      (selectedPage.selected * itemsPerPage) % userReviewList?.length;
    setPageOffset(newOffset);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedReviewList.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  console.log(sortedReviewList);
  return (
    <div className="container-fluid background">
      <Header />
      <ReviewModal
        selectedReview={selectedReview}
        setViewModal={setViewModal}
        viewModal={viewModal}
        reviewId={selectedReview?._id}
      />
      <div className="mains">
        <div className="row">
          <div className="col">
            {" "}
            <h4 className="dash-head data mb-3">users review list</h4>
          </div>
          <div className="col">
            <div className="info_area">
              <div className="info_area m-2">
                <div className="row">
                  <div className="col-auto">
                    <i
                      className="approved_ "
                      title="Approved Review"
                      style={{ fontSize: "13px", userSelect: "none" }}
                    >
                      <Ai.AiOutlineCheckCircle /> Approved
                    </i>
                  </div>
                  <div className="col-auto">
                    <i
                      className="disabled_ "
                      title="Disabled Review"
                      style={{ fontSize: "13px", userSelect: "none" }}
                    >
                      <Ai.AiFillExclamationCircle /> Disabled
                    </i>
                  </div>

                  <div className="col-auto">
                    <i
                      className="pending_ "
                      title="Pending for Approval"
                      style={{ fontSize: "13px", userSelect: "none" }}
                    >
                      <Md.MdOutlinePending /> Pending
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row"></div>

        <div className="page-content">
          <div className="main-wrapper">
            <div className="mt-3">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="card table-widget col-md-12">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Reviewed company</th>
                              <th scope="col">Client</th>
                              <th scope="col">Date</th>
                              <th scope="col" className="text-center">
                                Status
                              </th>
                              <th scope="col">Comment</th>
                              <th className="text-center" scope="col">
                                View
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((review, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="row">{pageOffset + index + 1}</th>
                                  <td>{review?.businessName ?? "N/A"}</td>
                                  <td>{review?.user?.fullName ?? "N/A"}</td>
                                  <td>
                                    {review.creation_Date != null
                                      ? Utils.formatDate(review.creation_Date)
                                      : "N/A"}
                                  </td>
                                  <td className="text-center">
                                    {!review?.approved && !review?.disable ? (
                                      <>
                                        <i
                                          className="pending_"
                                          title="Pending for Approval"
                                        >
                                          <Md.MdOutlinePending />
                                        </i>
                                      </>
                                    ) : review?.approved && !review?.disable ? (
                                      <>
                                        <i
                                          className="approved_"
                                          title="Approved Review"
                                        >
                                          <Ai.AiOutlineCheckCircle />
                                        </i>
                                      </>
                                    ) : review?.approved && review?.disable ? (
                                      <>
                                        <i
                                          className="disabled_"
                                          title="Disabled Review"
                                        >
                                          <Ai.AiFillExclamationCircle />
                                        </i>
                                      </>
                                    ) : (
                                      <>
                                        <i
                                          className="disabled_"
                                          title="Disabled Review"
                                        >
                                          <Ai.AiFillExclamationCircle />
                                        </i>
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    <p className="review_data">
                                      {review?.protest ?? "N/A"}
                                    </p>
                                  </td>
                                  <td className="text-center">
                                    <button
                                      className="action_btn"
                                      onClick={() => handleOpenModal(review)}
                                    >
                                      <i>
                                        <Ai.AiOutlineEye />
                                      </i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="pagination">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      onPageChange={handlePageChange}
                      pageCount={Math.ceil(
                        sortedReviewList.length / itemsPerPage
                      )}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={3}
                      containerClassName={"pagination"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      disabledClassName={"disabled"}
                      activeClassName={"paging__link--active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Complaints;
