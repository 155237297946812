import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import { AppContext } from "../../Context/AppContext";
import * as Bs from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import { PulseLoader } from "react-spinners";
import { message, Popconfirm } from "antd";
import Backbutton from "../../Components/Header/Backbutton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function EditBusinessProfile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    categoryList,
    profileList,
    businessProfileLoading,
    addBusinessProfile,
    getBusinessProfile,
    deleteBusinessloading,
    deleteBusinessProfile,
    countyList,
    setBusinessProfileLoading,
  } = useContext(AppContext);

  const [businessProfile, setBusinessProfile] = useState({
    businessProfileId: "",
    businessName: "",
    websitelink: "",
    businesshours: "",
    description: "",
    businessCategory: "",
    address: [
      {
        line1: "",
        line2: "",
        locality: "",
        country: "",
        county: "",
        postalCode: "",
      },
    ],
  });
  const [profileAddress, setProfileAddress] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    findProfileById();
  }, [profileList]);

  useEffect(() => {
    setProfileAddress(businessProfile.address);
  }, [businessProfile.address]);

  useEffect(() => {
    insertAddress();
  }, [businessProfile]);

  const findProfileById = () => {
    if (!id) return;
    const profileData = profileList.find((x) => x._id == id);
    if (!profileData) return;
    const address = profileData.address;

    setBusinessProfile((prev) => ({
      ...prev,
      businessProfileId: id,
      businessName: profileData.businessName,
      websitelink: profileData.websitelink,
      businesshours: profileData.businesshours,
      description: profileData.description,
      businessCategory: profileData.businessCategory._id,
      address: address.map((adds) => ({
        line1: adds?.line1 ?? "",
        line2: adds?.line2 ?? "",
        locality: adds?.locality ?? "",
        country: adds?.country ?? "",
        county: adds?.county ?? "",
        postalCode: adds?.postalCode ?? "",
      })),
    }));
  };

  const insertAddress = () => {
    setProfileAddress(businessProfile.address);
  };

  const addNewAddress = () => {
    const newAddress = {
      line1: "",
      line2: "",
      locality: "",
      county: "",
      country: "",
      postalCode: "",
    };

    setProfileAddress([...profileAddress, newAddress]);
    setBusinessProfile((prev) => ({
      ...prev,
      address: [...prev.address, newAddress],
    }));
  };

  const deleteAddress = (deleteIndex) => {
    const updatedAddresses = profileAddress.filter(
      (_, index) => index !== deleteIndex
    );
    setProfileAddress(updatedAddresses);

    const updatedBusinessAddresses = businessProfile.address.filter(
      (_, index) => index !== deleteIndex
    );
    setBusinessProfile((prev) => ({
      ...prev,
      address: updatedBusinessAddresses,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e;
    return setBusinessProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressChange = (e, index) => {
    const updatedAddress = [...businessProfile.address];

    const { name, value } = e;
    updatedAddress[index] = {
      ...updatedAddress[index],
      [name]: value,
      country: "United Kingdom",
    };
    setBusinessProfile((prev) => ({
      ...prev,
      address: updatedAddress,
    }));
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!businessProfile.businessName) {
      formIsValid = false;
      errors["businessName"] = "Business Name cannot be empty.";
    }

    if (!businessProfile.businessCategory) {
      formIsValid = false;
      errors["businessCategory"] = "Please select a business category.";
    }

    if (
      !businessProfile.websitelink ||
      !/^https?:\/\/[^\s]+$/i.test(businessProfile.websitelink)
    ) {
      formIsValid = false;
      errors["websitelink"] = "Please enter a valid URL.";
    }

    if (!businessProfile.businesshours) {
      formIsValid = false;
      errors["businesshours"] = "Working Hours cannot be empty.";
    }

    if (businessProfile.address.length === 0) {
      formIsValid = false;
      errors["address"] = "At least one address is required.";
    } else {
      businessProfile.address.forEach((address, index) => {
        if (!address.line1) {
          formIsValid = false;
          errors[`addressLine1_${index}`] = `Address ${
            index + 1
          } Line 1 cannot be empty.`;
        }
        if (!address.county) {
          formIsValid = false;
          errors[`addressCounty_${index}`] = `Address ${
            index + 1
          } County cannot be empty.`;
        }
        const ukPostalCodeRegex =
          /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i;
        if (!ukPostalCodeRegex.test(address.postalCode)) {
          formIsValid = false;
          errors[`addressPostalCode_${index}`] = `Address ${
            index + 1
          } has an invalid postal code.`;
        }
      });
    }
    console.log(errors);
    setError(errors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = handleValidation();

    if (isValid) {
      const formData = new FormData();
      formData.append("businessProfileId", businessProfile.businessProfileId);
      formData.append("businessName", businessProfile.businessName);
      formData.append("description", businessProfile.description);
      formData.append("websitelink", businessProfile.websitelink);
      formData.append("businesshours", businessProfile.businesshours);
      formData.append("businessCategory", businessProfile.businessCategory);
      formData.append("address", JSON.stringify(businessProfile.address));

      await addBusinessProfile(formData, {
        onSuccess: async (res) => {
          toast.success("Updated Successfully!", {
            style: {
              backgroundColor: "#fff",
              padding: "16px",
              color: "#252525",
            },
          });
          await getBusinessProfile();
          setTimeout(() => {
            navigate("/businessprofile");
          }, 1000);
        },
        onFailed: (err) => {
          setBusinessProfileLoading(false);
          toast.error(err.message);
        },
      });
    } else {
      toast.error("Please fill all mandatory Fields!");
    }
  };

  // const confirmDelete = async () => {
  //   const payload = {
  //     businessProfileId: id,
  //   };

  //   await deleteBusinessProfile(payload, {
  //     onSuccess: async (res) => {
  //       toast.success(res.message);
  //       await getBusinessProfile();
  //       setTimeout(() => {
  //         navigate("/businessprofile");
  //       }, 1000);
  //     },
  //     onFailed: (err) => {
  //       toast.error(err.message);
  //     },
  //   });
  // };

  return (
    <div className="container-fluid background">
      <Toaster position="top-center" />
      <Header />
      <div className="mains">
        <div class="edit-title">
          <div class="start-edit">
            <Backbutton />
          </div>
          <div class="center-edit text-center">
            <h4 class="dash-head text-center data">edit Business Profile</h4>
          </div>
        </div>

        <div className="section add-category">
          <div className="card review_card add_business_card">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div class="mb-3 col-lg-6 col-sm-12">
                  <label
                    for="exampleInputEmail1"
                    class="form-label review-label"
                  >
                    Company Name
                  </label>
                  <span className="required_">*</span>
                  <input
                    name="businessName"
                    type="text"
                    class="form-control"
                    value={businessProfile?.businessName ?? "N/A"}
                    onChange={(e) => handleChange(e.target)}
                  />
                  {error.businessName && (
                    <div className="error">{error.businessName}</div>
                  )}
                </div>
                <div class="mb-3  col-lg-6 col-sm-12">
                  <label
                    for="exampleInputPassword1"
                    class="form-label review-label"
                  >
                    Category
                  </label>
                  <span className="required_">*</span>
                  <select
                    className="form-select"
                    name="businessCategory"
                    onChange={(e) => handleChange(e.target)}
                  >
                    {categoryList.map((category, index) => {
                      return (
                        <option
                          value={category?._id}
                          key={index}
                          selected={
                            businessProfile.businessCategory != null &&
                            category._id == businessProfile.businessCategory
                          }
                        >
                          {category?.businessCategoryName ?? "N/A"}
                        </option>
                      );
                    })}
                  </select>
                  {error.category && (
                    <div className="error">{error.category}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div class="mb-3 col-lg-6 col-sm-12">
                  <label
                    name="websitelink"
                    for="exampleInputEmail1"
                    class="form-label review-label"
                  >
                    Website URL
                  </label>
                  <input
                    name="websitelink"
                    type="link"
                    class="form-control"
                    value={businessProfile.websitelink}
                    onChange={(e) => handleChange(e.target)}
                  />
                  {error.websitelink && (
                    <div className="error">{error.websitelink}</div>
                  )}
                </div>

                <div class="mb-3 col-lg-6 col-sm-12">
                  <label
                    name="websitelink"
                    for="exampleInputEmail1"
                    class="form-label review-label"
                  >
                    Working Hours
                  </label>
                  <span className="required_">*</span>
                  <input
                    name="businesshours"
                    type="link"
                    class="form-control"
                    value={businessProfile.businesshours}
                    pattern="^[0-9]*$"
                    title="Please enter only number values"
                    onChange={(e) => handleChange(e.target)}
                  />
                  {error.businesshours && (
                    <div className="error">{error.businesshours}</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="form-label review-label">Description</div>
                <ReactQuill
                  theme="snow"
                  name="description"
                  value={businessProfile?.description}
                  onChange={(e) =>
                    setBusinessProfile((prev) => ({ ...prev, description: e }))
                  }
                  className="business_rich_editor"
                />
                {/* <textarea
                  id=""
                  cols="30"
                  rows="10"
                  className="form-control edit_description"
                  value={businessProfile?.description ?? "N/A"}
                  onChange={(e) => handleChange(e.target)}
                ></textarea> */}
              </div>
              <br />
              <div className="address-container">
                <div className="d-flex">
                  <h5>Address</h5>
                  <button
                    type="button"
                    className="review_add_address_btn"
                    onClick={() => addNewAddress()}
                  >
                    +
                  </button>
                </div>
                <br />
                {businessProfile.address != null &&
                  businessProfile.address.map((address, index) => {
                    return (
                      <>
                        <div className="card px-3">
                          <div className="row mt-3" key={index}>
                            <div class="mb-3 col-lg-4 col-sm-12">
                              <label
                                htmlFor=""
                                className="form-label review-label"
                              >
                                Line1
                              </label>{" "}
                              <span className="required_">*</span>
                              <input
                                name="line1"
                                type="text"
                                class="form-control"
                                placeholder="House Number and street"
                                value={businessProfile.address[index]?.line1}
                                onChange={(e) =>
                                  handleAddressChange(e.target, index)
                                }
                              />
                              {error[`addressLine1_${index}`] && (
                                <div className="error">
                                  {error[`addressLine1_${index}`]}
                                </div>
                              )}
                            </div>
                            <div class="mb-3  col-lg-4 col-sm-12">
                              <label
                                htmlFor=""
                                className="form-label review-label"
                              >
                                Line2
                              </label>
                              <input
                                name="line2"
                                type="text"
                                class="form-control"
                                placeholder=""
                                value={businessProfile.address[index]?.line2}
                                onChange={(e) =>
                                  handleAddressChange(e.target, index)
                                }
                              />
                            </div>
                            <div class="mb-3  col-lg-4 col-sm-12">
                              <label
                                htmlFor=""
                                className="form-label review-label"
                              >
                                Locality
                              </label>
                              <input
                                name="locality"
                                type="text"
                                class="form-control"
                                placeholder="Locality"
                                value={businessProfile.address[index]?.locality}
                                onChange={(e) =>
                                  handleAddressChange(e.target, index)
                                }
                              />
                            </div>
                            <div class="mb-3 col-lg-4 col-sm-12">
                              <label
                                htmlFor=""
                                className="form-label review-label"
                              >
                                Country
                              </label>
                              <input
                                name="country"
                                type="text"
                                class="form-control"
                                placeholder="UNITED KINGDOM"
                                value={businessProfile.address[index]?.country}
                                disabled
                              />
                            </div>
                            <div class="mb-3 col-lg-4 col-sm-12">
                              <label
                                htmlFor=""
                                className="form-label review-label"
                              >
                                County
                              </label>{" "}
                              <span className="required_">*</span>
                              {/* <select
                                name="county"
                                id=""
                                className="form-control form-select"
                                onChange={(e) => {
                                  handleAddressChange(e.target, index);
                                }}
                              >
                                {countyList != null &&
                                  countyList.map((list, index) => {
                                    const isSelected =
                                      businessProfile.address[index]?.county ==
                                      list?.name;
                                    return (
                                      <option
                                        key={index}
                                        value={list?.name}
                                        name="county"
                                        selected={isSelected}
                                      >
                                        {list?.name ?? "N/A"}
                                      </option>
                                    );
                                  })}
                              </select> */}
                              <select
                                name="county"
                                id=""
                                className="form-control form-select"
                                onChange={(e) =>
                                  handleAddressChange(e.target, index)
                                }
                                value={
                                  businessProfile.address[index]?.county || ""
                                }
                              >
                                <option value="" disabled>
                                  Select
                                </option>
                                {countyList != null &&
                                  countyList.map((list, idx) => (
                                    <option key={idx} value={list?.name}>
                                      {list?.name ?? "N/A"}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div class="mb-3 col-lg-4 col-sm-12">
                              <label
                                htmlFor=""
                                className="form-label review-label"
                              >
                                Postal Code
                              </label>{" "}
                              <span className="required_">*</span>
                              <input
                                name="postalCode"
                                type="text"
                                class="form-control"
                                placeholder="postal code"
                                value={
                                  businessProfile.address[index]?.postalCode
                                }
                                onChange={(e) =>
                                  handleAddressChange(e.target, index)
                                }
                              />
                            </div>
                          </div>
                          <button
                            type="button"
                            className="review_btn_remove_address"
                            onClick={() => deleteAddress(index)}
                          >
                            <Bs.BsTrash />
                          </button>
                        </div>
                        <br />
                      </>
                    );
                  })}
              </div>
              <div className="text-center mt-3 d-flex">
                <button type="submit" class="btn btn-primary">
                  {businessProfileLoading ? (
                    <PulseLoader color="#fff" size={10} />
                  ) : (
                    "Save"
                  )}
                </button>
                {/* <Popconfirm
                  title="Delete the Business Profile"
                  description="Are you sure to delete this Business Profile?"
                  onConfirm={confirmDelete}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <button type="button" className="profile_delete_btn">
                    <Bs.BsTrashFill />
                  </button>
                </Popconfirm> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBusinessProfile;
