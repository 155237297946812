import React, { useContext } from "react";
import Lottie from "react-lottie";
import LottieFile from "../../Assets/Lottie/94900-error.json";
import "./Error.css";
import { AppContext } from "../../Context/AppContext";

function ErrorComponent(props) {
  const { setCookieError } = useContext(AppContext);
  const lottieOption = {
    loop: true,
    autoplay: true,
    animationData: LottieFile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="error-container">
        <div className="error-content">
          <h1 className="text-center">{props.errorTitle}</h1>
          <div className="err-img">
            <Lottie options={lottieOption} width={250} height={250}></Lottie>
          </div>
          <p className="err-desc">{props.errorMsg}</p>
          <div className="row text-center">
            <div className="col mx-auto">
              <button
                className="review-err"
                onClick={() => setCookieError(null)}
              >
                Retry
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorComponent;
