import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import * as Ci from "react-icons/ci";
import "./BusinessCategory.css";
import toast, { Toaster } from "react-hot-toast";
import { AppContext } from "../../Context/AppContext";
import * as Bs from "react-icons/bs";
import ReactPaginate from "react-paginate";

function BusinessCategory() {
  const navigate = useNavigate();
  const { categoryList } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = categoryList.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [categoryList]);

  return (
    <div className="container-fluid background">
      <Header />
      <div className="mains">
        <Toaster position="top-center" />

        <div className="">
          <div className="table-wrapper">
            <div className="table-title">
              <div className="m-1 p-2"></div>
              <div className="flex-cont mb-3">
                <div className="flex-start m-1">
                  <h4 className="dash-head text-center">
                    List of Business Categories
                  </h4>
                </div>

                <div className="flex-end">
                  <button
                    type="button"
                    className="btn btn-add"
                    onClick={() => navigate("/addbusinesscategory")}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>

            <table className="table tab-back dash_table table-hover p-3">
              <thead className="thead-light">
                <tr>
                  <th>#</th>
                  <th>BusinessCategory</th>
                  <th>Image</th>
                  <th className="text-center">Edit</th>
                  {/* <th className="text-center">Delete</th> */}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((category, index) => (
                  <tr key={category._id}>
                    <th scope="row">{index + 1}</th>
                    <td>{category?.businessCategoryName ?? "N/A"}</td>
                    <td>
                      <img
                        src={
                          category.image != null
                            ? process.env.REACT_APP_AWS_IMAGE_URL +
                              "categoryimages/" +
                              category.image
                            : "Image is not available"
                        }
                        // alt="Image is not available"
                        className="category-img"
                      />
                    </td>
                    <td className="text-center">
                      <button
                        className="action_btn text-center"
                        onClick={() =>
                          navigate(`/editbusinesscategory/${category._id}`)
                        }
                      >
                        <i>
                          <Ci.CiEdit />
                        </i>
                      </button>
                    </td>
                    {/* <td className="text-center">
                      <button className="action_btn text-center">
                        <i>
                          <Bs.BsTrash />
                        </i>
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
            {categoryList.length >= 1 && (
              <div className="pagination">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  onPageChange={handlePageChange}
                  pageCount={Math.ceil(categoryList.length / itemsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  containerClassName={"pagination"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  disabledClassName={"disabled"}
                  activeClassName={"paging__link--active"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessCategory;
