import React, { useContext, useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import * as Ai from "react-icons/ai";
import { AppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

function Users() {
  const navigate = useNavigate();
  const { usersList } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 10;

  // Reset current page to 1 when search query changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  // Filter users based on search query
  const filteredUsers = usersList.filter(
    (user) =>
      user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const navigateViewUser = (user) => {
    window.sessionStorage.setItem("userData", JSON.stringify(user));
    navigate(`/viewuser/${user._id}`);
  };

  return (
    <div className="container-fluid background">
      <Header />
      <div className="mains">
        <h4 className="dash-head data mb-3">LIST OF USERS</h4>

        {/* Search Input */}
        <div className="search-container mb-3 col-md-4 col-sm-6">
          <input
            type="text"
            className="form-control"
            placeholder="Search user by Full Name or Email"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="card add-category per-table">
          <table className="table per-table p-3">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Full Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col" className="text-center">
                  Status
                </th>
                <th className="text-center">View</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((user, index) => (
                  <tr key={user._id}>
                    <th>{indexOfFirstItem + index + 1}</th>
                    <td>{user.fullName ? user.fullName : "Not available"}</td>
                    <td>{user.email ? user.email : "Not available"}</td>
                    <td>{user.phone ? user.phone : "Not available"}</td>
                    <td>
                      {!user?.ban ? (
                        <div className="review-badge active">Active</div>
                      ) : (
                        <div className="review-badge banned">Banned</div>
                      )}
                    </td>
                    <td className="text-center">
                      <button
                        className="action_btn"
                        onClick={() => navigateViewUser(user)}
                      >
                        <Ai.AiOutlineEye />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No users found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            onPageChange={handlePageChange}
            pageCount={Math.ceil(filteredUsers.length / itemsPerPage)}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            containerClassName={"pagination"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            disabledClassName={"disabled"}
            activeClassName={"paging__link--active"}
          />
        </div>
      </div>
    </div>
  );
}

export default Users;
