import React, { Fragment, useContext, useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import "./Users.css";
import User from "../../Assets/user.png";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import * as Ai from "react-icons/ai";
import * as Hi from "react-icons/hi";
import Utils from "../../Utils/Utils";
import Backbutton from "../../Components/Header/Backbutton";
import { Modal } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { PulseLoader } from "react-spinners";
import { useRef } from "react";

function ViewUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const inpRef = useRef();
  const {
    userReviewList,
    banSingleUser,
    banLoading,
    getAllUsers,
    unBanSingleUser,
    usersList,
    editUserProfile,
    userListLoading,
    deleteUser,
  } = useContext(AppContext);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [profileValues, setProfileValues] = useState({
    fullName: "",
    email: "",
    profilepic: null,
    phone: "",
  });
  const [filePreview, setFilePreview] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    reviewList();
  }, [userReviewList]);

  useEffect(() => {
    getUserData();
  }, [usersList]);

  const getUserData = () => {
    if (!id) return;
    const data = usersList.find((x) => x._id == id);
    setUserData(data);
    setProfileValues(data);
  };

  const reviewList = () => {
    if (!id) return;
    const getReviewData = userReviewList.filter((x) => x.user?._id === id);
    setReviewData(getReviewData);
    const user = getReviewData[0]?.user;
    setUserData(user);
  };
  const hideConfirmMoal = async () => {
    setShowConfirmModal(false);
  };

  const handleConfirmBan = async () => {
    const payload = {
      userId: id,
    };

    await banSingleUser(payload, {
      onSuccess: async (res) => {
        toast.success("User Banned Successfully!");
        await getAllUsers();

        setTimeout(() => {
          navigate("/users");
        }, 1000);
      },
      onFailed: (err) => {
        console.log(err);
        toast.error("Something wrong with banning user, Please try again!");
      },
    });
  };

  const handleUnbanUser = async () => {
    if (!id) return;

    const payload = {
      userId: id,
    };

    await unBanSingleUser(payload, {
      onSuccess: async (res) => {
        toast.success("User Unbanned Successfully!");
        await getAllUsers();

        setTimeout(() => {
          navigate("/users");
        }, 1000);
      },
      onFailed: (err) => {
        console.log(err);
        toast.error(
          "Something wrong with Unbanning the user, Please try again!"
        );
      },
    });
  };

  const handleFileUploadClick = () => {
    inpRef.current.click();
  };

  const handleFileChange = (file) => {
    if (!file) return;

    const objURL = URL.createObjectURL(file);
    setFilePreview(objURL);

    setProfileValues((prev) => ({ ...prev, profilepic: file }));
  };

  const handleProfileEdit = async (e) => {
    e.preventDefault();

    if (
      profileValues.fullName.length == 0 ||
      profileValues.email.length == 0 ||
      profileValues.phone.length == 0
    ) {
      setError(true);
      return;
    }

    const formData = new FormData();
    formData.append("userId", id);
    formData.append("fullName", profileValues.fullName);
    formData.append("email", profileValues.email);
    formData.append("phone", profileValues.phone);

    if (
      typeof profileValues.profilepic == "object" &&
      profileValues.profilepic != null
    ) {
      formData.append("image", profileValues.profilepic);
    }

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    await editUserProfile(formData, {
      onSuccess: async (res) => {
        toast.success("Profile Updated Successfully!");
        setEdit(false);
        await getAllUsers();

        setTimeout(() => {
          window.location.href = "/users";
        }, 800);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };
  const toggleDeleteConfirmationModal = () => {
    setDeleteConfirmationVisible(!deleteConfirmationVisible);
  };
  const showDeleteConfirmation = () => {
    toggleDeleteConfirmationModal();
  };
  const handleDeleteUser = async () => {
    if (!id) return;
    const userId = id;
    await deleteUser(userId, {
      onSuccess: async (res) => {
        toast.success("User Deleted Successfully!");
        setDeleteConfirmationVisible(false);
        await getAllUsers();
        setTimeout(() => {
          navigate("/users");
        });
      },
      onFailed: (err) => {
        toast.error("Something went Wrong!");
        console.error(err);
      },
    });
  };
  console.log(profileValues, "profileValues");
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <Modal
        title="User Action"
        open={showConfirmModal}
        onCancel={hideConfirmMoal}
        onOk={handleConfirmBan}
        okText="Confirm Ban"
        cancelText="Discard"
        okButtonProps={{ style: { background: "#f44336" } }}
        cancelButtonProps={{
          style: { borderColor: "#f44336", color: "#f44336" },
        }}
      >
        <h5>{`Ban ${userData?.fullName}?`}</h5>
        <p>
          Are you sure about banning this user? This action may lead to
          authentication issues for the affected user.
        </p>
      </Modal>
      <Modal
        title="Delete User Confirmation"
        visible={deleteConfirmationVisible}
        onOk={handleDeleteUser}
        okButtonProps={{ style: { background: "#f44336" } }}
        cancelButtonProps={{
          style: { borderColor: "#f44336", color: "#f44336" },
        }}
        onCancel={toggleDeleteConfirmationModal}
      >
        <p>Are you sure you want to delete this user?</p>
      </Modal>
      <div className="container-fluid background">
        <Header />

        <div className="mains">
          <div className="container data">
            <div className="float-right"> </div>

            <Backbutton />
            <div className="row ">
              <div className="col-xl-6 col-md-6 col-sm-12 p-1">
                <div className="card p-3">
                  {userData?.ban ? (
                    <button
                      className="unban_btn"
                      onClick={handleUnbanUser}
                      disabled={banLoading}
                    >
                      {!banLoading ? (
                        <Fragment>
                          <Ai.AiOutlineCheck /> UnBan User
                        </Fragment>
                      ) : (
                        <PulseLoader color="#fff" size={10} />
                      )}
                    </button>
                  ) : (
                    <button
                      className="ban_btn"
                      onClick={() => setShowConfirmModal(true)}
                      disabled={banLoading}
                    >
                      <Hi.HiBan /> Ban User
                    </button>
                  )}
                  {!edit ? (
                    <div className="flex">
                      {profileValues != null && (
                        <>
                          <div className="flex-one">
                            <img
                              src={
                                profileValues?.profilepic != null
                                  ? process.env.REACT_APP_AWS_IMAGE_URL +
                                    `userprofilepics/` +
                                    profileValues?.profilepic
                                  : User
                              }
                              alt=""
                              className="pro-img"
                            />
                          </div>
                          <div className="flex-two ms-3">
                            <h6 className="card-name">
                              {profileValues?.fullName != null
                                ? profileValues?.fullName
                                : "Not Available"}
                            </h6>
                            <p className="comp-para pt-2 p-1 mb-0">
                              <span className="comp-card">
                                {profileValues?.email}
                              </span>
                            </p>
                            <p className="comp-para">
                              <span className="comp-card">
                                {profileValues?.phone}
                              </span>
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <Fragment>
                      <form onSubmit={(e) => handleProfileEdit(e)}>
                        <div className="row">
                          <div className="col-auto">
                            <div className="flex-one">
                              <img
                                src={
                                  profileValues?.profilepic != null &&
                                  typeof profileValues.profilepic !== "object"
                                    ? process.env.REACT_APP_AWS_IMAGE_URL +
                                      `userprofilepics/` +
                                      profileValues?.profilepic
                                    : filePreview != null
                                    ? filePreview
                                    : User
                                }
                                onClick={handleFileUploadClick}
                                alt=""
                                className="pro-img"
                              />
                              <input
                                type="file"
                                name=""
                                id=""
                                className="inp_file"
                                onChange={(e) =>
                                  handleFileChange(e.target.files[0])
                                }
                                ref={inpRef}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group mb-2">
                              <label htmlFor="" className="review-label">
                                Full Name
                              </label>
                              <input
                                type="text"
                                className="form-control review-input"
                                value={profileValues?.fullName}
                                onChange={(e) =>
                                  setProfileValues((prev) => ({
                                    ...prev,
                                    fullName: e.target.value,
                                  }))
                                }
                              />
                              {error && profileValues.fullName.length == 0 && (
                                <p className="error_">User name is Required!</p>
                              )}
                            </div>
                            <div className="form-group mb-2">
                              <label htmlFor="" className="review-label">
                                Email Address
                              </label>
                              <input
                                type="email"
                                className="form-control review-input"
                                value={profileValues?.email}
                                onChange={(e) =>
                                  setProfileValues((prev) => ({
                                    ...prev,
                                    email: e.target.value,
                                  }))
                                }
                              />
                              {error && profileValues.email.length == 0 && (
                                <p className="error_">
                                  Email Address is Required!
                                </p>
                              )}
                            </div>
                            <div className="form-group ">
                              <label htmlFor="" className="review-label">
                                Phone number
                              </label>
                              <input
                                type="phone"
                                className="form-control review-input"
                                value={profileValues?.phone}
                                onChange={(e) =>
                                  setProfileValues((prev) => ({
                                    ...prev,
                                    phone: e.target.value,
                                  }))
                                }
                              />
                              {error && profileValues.phone.length == 0 && (
                                <p className="error_">
                                  Phone number is Required!
                                </p>
                              )}
                            </div>
                            <button
                              type="submit"
                              className="submit_btn"
                              disabled={userListLoading}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </Fragment>
                  )}
                  <button
                    className="edit_profile"
                    onClick={() => setEdit(!edit)}
                  >
                    {!edit ? " Edit Profile" : "Close"}
                  </button>
                </div>
              </div>
            </div>
            <p className="delete-pa" onClick={showDeleteConfirmation}>
              Delete this User?
            </p>
            <div className="row">
              {reviewData != null &&
                reviewData.map((review, index) => {
                  return (
                    <div
                      key={index}
                      className="col-xl-6 col-md-6 col-sm-12 p-1"
                    >
                      <div className="">
                        <div className="card p-3">
                          <div className="flex">
                            <div className="flex-two">
                              <h6 className="card-name">
                                {review.businessName
                                  ? review.businessName
                                  : "Not Available"}
                              </h6>
                              <p className="card-comment mt-3">
                                {" "}
                                {review.protest
                                  ? review.protest
                                  : "Not Available"}
                              </p>
                              <div className="card-comment hov">
                                <Ai.AiFillCalendar />{" "}
                                {review.creation_Date != null
                                  ? Utils.formatDate(review.creation_Date)
                                  : "Not Available"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {reviewData.length == 0 && (
            <h1 className="nothing_to_show">Nothing to Show</h1>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewUser;
