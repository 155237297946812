import BaseClient from "../../src/Helpers/BaseClient";
import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";

const useBusinessProfile = () => {
  const [businessProfileLoading, setBusinessProfileLoading] = useState(false);
  const [profile, setProfile] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [deleteBusinessloading, setDeleteBusinessLoading] = useState(false);
  const [countyList, setCountyList] = useState([]);

  // Add Business Profile
  const addBusinessProfile = async (payload, { onSuccess, onFailed }) => {
    try {
      setBusinessProfileLoading(true);
      await BaseClient.post(APIEndpoints.addBusinessProfile, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setBusinessProfileLoading(false);
    }
  };

  // Get Business Profile
  const getBusinessProfile = async () => {
    try {
      setBusinessProfileLoading(true);
      await BaseClient.get(APIEndpoints.getBusinessProfile, {
        onSuccess: (res) => {
          if (Array.isArray(res.data)) {
            setProfileList(res.data);
          }
        },
        onFailed: (err) => {
          console.log("ERROR ON GET BUSINESS PROFILE");
        },
      });
    } finally {
      setBusinessProfileLoading(false);
    }
  };

  //Delete Business Profile
  const deleteBusinessProfile = async (payload, { onSuccess, onFailed }) => {
    try {
      setDeleteBusinessLoading(true);
      await BaseClient.post(APIEndpoints.removeBusinessProfile, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setDeleteBusinessLoading(false);
    }
  };

  //Get County List
  const getAllCountyLists = async () => {
    try {
      setBusinessProfileLoading(true);
      await BaseClient.get(APIEndpoints.getAllCountiesList, {
        onSuccess: (res) => {
          if (Array.isArray(res.data)) {
            setCountyList(res.data);
          }
        },
        onFailed: (err) => {
          console.log("ERROR ON GET COUNTY LIST");
        },
      });
    } finally {
      setBusinessProfileLoading(false);
    }
  };
  //delete
  const deleteProfile = async (id, { onSuccess, onFailed }) => {
    try {
      setBusinessProfileLoading(true);

      await BaseClient.delete(APIEndpoints.deleteBusinessProfile + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setBusinessProfileLoading(false);
    }
  };
  return {
    profile,
    addBusinessProfile,
    businessProfileLoading,
    profileList,
    getBusinessProfile,
    deleteBusinessloading,
    setBusinessProfileLoading,
    deleteProfile,
    deleteBusinessProfile,
    getAllCountyLists,
    countyList,
  };
};

export default useBusinessProfile;
