import React, { useContext, useState } from "react";
import Header from "../../Components/Header/Header";
import { AppContext } from "../../Context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import Backbutton from "../../Components/Header/Backbutton";
import { useNavigate } from "react-router-dom";

import { PulseLoader } from "react-spinners";

function AddComplaintCategory() {
  const navigate = useNavigate();
  const { addComplaintCategory, getComplaintCategory, categoryLoading } =
    useContext(AppContext);
  const [categoryValues, setCategoryValues] = useState({
    complaintCategoryName: "",
    complaintCategoryDescription: "",
  });

  const [error, setError] = useState(false);
  const [reviewLoading, setReviewLoading] = useState(false);
  const formSubmit = async (e) => {
    e.preventDefault();
    setReviewLoading(true);
    if (
      categoryValues.complaintCategoryDescription.length === 0 ||
      categoryValues.complaintCategoryName.length === 0
    ) {
      setError(true);
      setReviewLoading(false);
      return;
    }

    await addComplaintCategory(categoryValues, {
      onSuccess: async (res) => {
        toast.success(res.message);
        setReviewLoading(false);
        // setCategoryValues({
        //   complaintCategoryName: "",
        //   complaintCategoryDescription: "",
        // });

        await getComplaintCategory();

        setTimeout(() => {
          navigate("/complaint-category");
        }, 1000);
      },
      onFailed: (e) => {
        toast.error(e.message);
        setReviewLoading(false);
      },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategoryValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="container-fluid background">
      <Toaster position="top-center" />
      <Header />
      <div className="mains">
        <Toaster position="top-center" />
        <div class="edit-title data mb-3">
          <div class="start-edit">
            <Backbutton />
          </div>
          <div class="center-edit text-center">
            <h4 class="dash-head text-center">Add Review Category</h4>
          </div>
        </div>
        <div className="add-category">
          <div className="card review_card">
            <form onSubmit={formSubmit}>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Review Category
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="complaintCategoryName"
                  value={categoryValues.complaintCategoryName}
                  placeholder="Please Fill Category Name"
                  onChange={handleInputChange}
                />
                {error && categoryValues.complaintCategoryName.length === 0 ? (
                  <span style={{ color: "red" }}>
                    Please fill the category name
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Description
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="complaintCategoryDescription"
                  value={categoryValues.complaintCategoryDescription}
                  placeholder="Please describe this Review category"
                  onChange={handleInputChange}
                />
                {error &&
                categoryValues.complaintCategoryDescription.length === 0 ? (
                  <span style={{ color: "red" }}>
                    Please fill the description
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="text-center">
                <button type="submit" class="btn btn-danger">
                  {reviewLoading ? (
                    <PulseLoader color="#fff" size={10} />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddComplaintCategory;
