import React, { useContext, useState, Fragment, useEffect } from "react";
import Utils from "../../Utils/Utils";
import { Modal, Popconfirm } from "antd";
import * as Bs from "react-icons/bs";
import * as Bi from "react-icons/bi";
import * as Ai from "react-icons/ai";
import * as Ri from "react-icons/ri";
import { AppContext } from "../../Context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

function ReviewModal({ viewModal, setViewModal, selectedReview, reviewId }) {
  const navigate = useNavigate();
  const {
    reviewLoading,
    deleteSingleReview,
    getAllUserReview,
    approveSingleReview,
    editReviewData,
  } = useContext(AppContext);
  const [editCmnt, setEditCmnt] = useState(false);
  const [comments, setComments] = useState();
  const [disable, setDisable] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);

  useEffect(() => {
    if (!selectedReview) return;

    setComments(selectedReview?.protest);
    setDisable(selectedReview?.disable);
  }, [selectedReview]);

  if (!viewModal || !reviewId) return;

  const handleConfirmDelete = async () => {
    await deleteSingleReview(reviewId, {
      onSuccess: async (res) => {
        toast.success("User Review Deleted Successfully!");
        await getAllUserReview();
        setTimeout(() => {
          setViewModal(false);
        }, 800);
      },
      onFailed: (err) => {
        toast.error("Something went Wrong!");
        console.error(err);
      },
    });
  };

  const approveReview = async () => {
    const payload = {
      reviewId: reviewId,
    };
    await approveSingleReview(payload, {
      onSuccess: async (res) => {
        toast.success("User Review Approved Successfully!");
        await getAllUserReview();

        setViewModal(false);
      },
      onFailed: (err) => {
        toast.error("Something went Wrong!");
        console.error(err);
      },
    });
  };

  const saveComment = async () => {
    const formData = new FormData();

    formData.append("reviewId", selectedReview?._id);
    formData.append("user", selectedReview?.user?._id);
    formData.append("protest", comments);

    await editReviewData(formData, {
      onSuccess: async (res) => {
        toast.success("User Review Updated Successfully!");
        await getAllUserReview();
        setViewModal(false);
        setEditCmnt(false);
      },
      onFailed: (err) => {
        toast.error("Something went Wrong!");
        console.error(err);
      },
    });
  };

  const handleDisableEnableReview = async () => {
    const formData = new FormData();
    formData.append("reviewId", selectedReview?._id);
    formData.append("user", selectedReview?.user?._id);
    formData.append("disable", !disable);

    setDisableLoading(true);

    await editReviewData(formData, {
      onSuccess: async (res) => {
        setDisableLoading(false);
        if (disable) {
          toast.success("User Review is Disabled!");
        } else {
          toast.success("User Review is Enabled!");
        }
        await getAllUserReview();
        setViewModal(false);
        setEditCmnt(false);
      },
      onFailed: (err) => {
        setDisableLoading(false);
        toast.error("Something went Wrong!");
        console.error(err);
      },
    });
  };

  const navigateViewProfile = () => {
    window.location.href = `/viewuser/${selectedReview?.user?._id}`;
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <Modal
        title="Reviews"
        open={viewModal}
        onCancel={() => {
          setViewModal(false);
          setEditCmnt(false);
        }}
        footer={null}
      >
        <h2>{selectedReview?.businessName}</h2>
        <p>{Utils.formatDate(selectedReview.creation_Date)}</p>
        {!disableLoading ? (
          <button
            className={disable ? "disable_btn disabled" : "disable_btn enable"}
            onClick={handleDisableEnableReview}
          >
            {!disable ? (
              <Fragment>
                <i>
                  <Ai.AiFillEyeInvisible />
                </i>
                Disable Review{" "}
              </Fragment>
            ) : (
              <Fragment>
                <i>
                  <Ai.AiFillEye />
                </i>
                Enable Review{" "}
              </Fragment>
            )}
          </button>
        ) : (
          "Loading..."
        )}
        <p className="m-0">
          <b>{selectedReview?.user?.fullName ?? "N/A"}</b>
          <span className="ps-3">{selectedReview?.user?.email ?? "N/A"}</span>
        </p>
        <p className="comp_connection">{selectedReview?.companyConnection}</p>
        <button
          className="edit_cmnt"
          title="Edit this comment"
          onClick={() => setEditCmnt(!editCmnt)}
        >
          {!editCmnt ? <Ai.AiOutlineEdit /> : <Ai.AiOutlineClose />}
        </button>
        <div className="comment">
          {!editCmnt ? (
            <p>{selectedReview?.protest ?? "N/A"}</p>
          ) : (
            <textarea
              onChange={(e) => setComments(e.target.value)}
              value={comments}
            />
          )}
        </div>
        {selectedReview?.approved && (
          <p className="approved_status ">
            <Bs.BsCheckCircleFill /> Approved Review
          </p>
        )}
        <div className="row mt-2">
          {!selectedReview?.approved && (
            <div className="col-auto px-1">
              <button
                className="modal_btn approve_btn"
                disabled={
                  reviewLoading.action == "approve" && reviewLoading.status
                }
                onClick={approveReview}
              >
                {reviewLoading.action == "approve" && reviewLoading.status ? (
                  <PulseLoader color="#fff" size={10} />
                ) : (
                  <Fragment>
                    <i>
                      <Bs.BsCheck2All />
                    </i>{" "}
                    Approve
                  </Fragment>
                )}
              </button>
            </div>
          )}
          {/* <div className="col-auto px-1">
            <button
              className="modal_btn visit_profile"
              onClick={() => navigateViewProfile()}
            >
              <i>
                <Bi.BiSolidUserPin />
              </i>{" "}
              Visit User Profile
            </button>
          </div> */}

          <div className="col-auto px-1">
            <Popconfirm
              description="Are you sure to delete this Review?"
              okText="Yes"
              cancelText="No"
              icon={<Ri.RiDeleteBack2Line className="fs-6 m-2" />}
              okButtonProps={{ style: { background: "#f44336" } }}
              onConfirm={handleConfirmDelete}
            >
              <button className="modal_btn cancel_btn">
                <i>
                  <Ai.AiFillDelete />
                </i>{" "}
                Delete Review
              </button>
            </Popconfirm>
          </div>
          {editCmnt && (
            <div className="col-auto px-1">
              <button
                className="modal_btn save_cmnt"
                onClick={saveComment}
                disabled={
                  reviewLoading.action == "edit" && reviewLoading.status
                }
              >
                {reviewLoading.action == "edit" && reviewLoading.status ? (
                  <PulseLoader color="#fff" size={10} />
                ) : (
                  <Fragment>
                    <i>
                      <Bi.BiSolidSave />
                    </i>{" "}
                    Save
                  </Fragment>
                )}
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default ReviewModal;
