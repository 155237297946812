export const APIEndpoints = {
  adminAuth: "auth/adminlogin",
  getCookieData: "cookies/getadmintoken",
  deleteCookieData: "cookies/deleteadmintoken",

  editReviewData: "admin/review/addoreditreview",

  getComplaintCategory: "admin/complaintcategory/getallcomplaintcategories",
  addComplaintCategory: "admin/complaintcategory/createoreditcomplaintcategory",

  addBusinessProfile: "admin/business/createoreditbusinessprofile",
  getBusinessProfile: "admin/business/getallbusinesprofiles",
  removeBusinessProfile: "admin/business/deletebusinessProfile",

  getAllBusinessCategoryList: "admin/businesscategory/getallbusinesscategories",
  addBusinessCategory: "admin/businesscategory/createoreditbusinesscategory",

  getAllUsers: "admin/user/getallusers",
  getAllReviews: "admin/review/getallreviews",
  deleteUserReview: "admin/review/deletereview",
  approveUserReview: "admin/review/approveReviewById",
  banSingleUser: "admin/user/banuserbyid",
  unBanSingleUser: "admin/user/unbanuserbyid",
  updateUserDetails: "admin/user/updateuser",

  getAllCountiesList: "admin/business/getallcountiesuk",
  deleteUser: "admin/user/deleteuser",
  deleteBusinessProfile: "admin/business/deletebusinessprofile",
  deleteReviewCategory: "admin/complaintcategory/deletecomplaintcategory",

  //Settings
  createSettings: "admin/pages/create-page",
  getAllPageData: "admin/pages/get-all-pages",
  updateContent: "admin/pages/update-page",
  publishedStatus:"admin/pages/enable-disable-page"
};
