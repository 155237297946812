import BaseClient from "../../src/Helpers/BaseClient";
import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";

const useComplaintCategory = () => {
  const [complaintCategoryList, setComplaintCategoryList] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [categories, setcategories] = useState(false);

  // Get Category
  const getComplaintCategory = async () => {
    try {
      setCategoryLoading(true);
      await BaseClient.get(APIEndpoints.getComplaintCategory, {
        onSuccess: (res) => {
          if (!Array.isArray(res.data)) return;
          setComplaintCategoryList(res.data);
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    } catch (error) {
      console.log(error);
      setCategoryLoading(false);
    }
  };

  /* Edit Therapists */
  const editComplaintCategory = async (data, id, { onSuccess, onFailed }) => {
    try {
      const payload = data;
      setcategories(true);

      await BaseClient.put(
        APIEndpoints.getComplaintCategory + `/${id}`,
        payload,
        {
          onSuccess: onSuccess,
          onFailed: onFailed,
        }
      );
    } finally {
      setcategories(false);
    }
  };

  // Post Method
  const addComplaintCategory = async (payload, { onSuccess, onFailed }) => {
    try {
      setCategoryLoading(true);
      await BaseClient.post(APIEndpoints.addComplaintCategory, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setCategoryLoading(false);
    }
  };
  //delete
  const deleteComplaintCategory = async (id, { onSuccess, onFailed }) => {
    try {
      setCategoryLoading(true);

      await BaseClient.delete(APIEndpoints.deleteReviewCategory + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setCategoryLoading(false);
    }
  };

  return {
    getComplaintCategory,
    deleteComplaintCategory,
    categories,
    addComplaintCategory,
    complaintCategoryList,
    categoryLoading,
    editComplaintCategory,
  };
};

export default useComplaintCategory;
