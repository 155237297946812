import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import placeholder from "../../Assets/placeholder.png";
import { AppContext } from "../../Context/AppContext";
import { PulseLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import Backbutton from "../../Components/Header/Backbutton";

function EditBusinessCategory() {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    categoryList,
    addBusinessCategory,
    businessCategoryLoading,
    getAllBusinesscategory,
  } = useContext(AppContext);

  const [initialValues, setInitialValues] = useState({
    businessCategoryId: "",
    businessCategoryName: "",
    image: null,
    imagePreview: null,
  });

  useEffect(() => {
    findBusinessCategory();
  }, [categoryList]);

  const findBusinessCategory = () => {
    if (!id) return;
    const categoryListData = categoryList.find((x) => x._id == id);
    setInitialValues((prev) => ({
      ...prev,
      businessCategoryId: id,
      businessCategoryName: categoryListData.businessCategoryName,
      image: categoryListData.image,
      imagePreview: categoryListData.image
        ? process.env.REACT_APP_AWS_IMAGE_URL +
          "categoryimages/" +
          categoryListData.image
        : null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      initialValues.businessCategoryName.length === 0 ||
      initialValues.businessCategoryId.length === 0
    )
      return;



    const formData = new FormData();
    formData.append("businessCategoryId", initialValues.businessCategoryId);
    formData.append("businessCategoryName", initialValues.businessCategoryName);
    if (typeof initialValues.image === "object" &&  initialValues.image != null) {
      formData.append("image", initialValues.image);
    }
 
    await addBusinessCategory(formData, {
      onSuccess: async (res) => {
        toast.success(res.message);
        await getAllBusinesscategory();
        setTimeout(() => {
          navigate("/businesscategory");
        }, 1000);
      },
      onFailed: (err) => {
        toast.error("Category is not Updated! Please try again");
      },
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setInitialValues((prev) => ({
      ...prev,
      image: file,
      imagePreview: URL.createObjectURL(file),
    }));
  };

  return (
    <div className="container-fluid background">
      <Toaster position="top-center" />
      <Header />
      <div className="mains">
        <div className="edit-title data">
          <div className="start-edit">
            <Backbutton />
          </div>
          <div className="center-edit text-center">
            <h4 className="dash-head text-center">Edit Business Category</h4>
          </div>
        </div>
        <div className="data">
          <div className="add-category">
            <div className="card review_card ">
              <div className="image_preview">
                <img
                  src={initialValues.imagePreview || placeholder}
                  alt=""
                  className="placeho"
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Business Category
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Please Fill Business category Name"
                    value={initialValues.businessCategoryName}
                    onChange={(e) =>
                      setInitialValues((prev) => ({
                        ...prev,
                        businessCategoryName: e.target.value,
                      }))
                    }
                  />
                  {initialValues.businessCategoryName.length === 0 && (
                    <span style={{ color: "red" }}>
                      Category Name is Required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Image Upload
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    placeholder="Please upload image"
                    onChange={handleImageChange}
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-danger">
                    {businessCategoryLoading ? (
                      <PulseLoader color="#fff" size={10} />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBusinessCategory;
