import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context/AppContext";
import toast from "react-hot-toast";
import { PulseLoader } from "react-spinners";
import { Switch } from "antd";

function Home() {
  const {
    pageData,
    updateContent,
    settingsLoading,
    fetchAllPageData,
    publushedStatusUpdate,
  } = useContext(AppContext);
  const [homeInfo, setHomeInfo] = useState(null);
  const [homeData, setHomeData] = useState({
    topHeading: "",
    heading1: "",
    heading2: "",
    heading3: "",
    para1: "",
    para2: "",
    para3: "",
    pageName: "",
    pageId: "",
  });
  const [published, setPublished] = useState(true);

  useEffect(() => {
    const data =
      pageData != null && pageData.find((x) => x.pageName === "Home");
    if (data) {
      setHomeInfo(data);
      setHomeData({
        topHeading: data.contents.topHeading || "",
        heading1: data.contents.heading1 || "",
        heading2: data.contents.heading2 || "",
        heading3: data.contents.heading3 || "",
        para1: data.contents.para1 || "",
        para2: data.contents.para2 || "",
        para3: data.contents.para3 || "",
        pageName: data.pageName,
        pageId: data._id,
      });
    }

    setPublished(data?.published);
  }, [pageData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHomeData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleHomePage = async () => {
    if (
      homeData.topHeading.length === 0 ||
      homeData.heading1.length === 0 ||
      homeData.heading2.length === 0 ||
      homeData.heading3.length === 0 ||
      homeData.para1.length === 0 ||
      homeData.para2.length === 0 ||
      homeData.para3.length === 0
    ) {
      toast.error("Please Update All Fields!");
      return;
    }
    const payload = {
      pageName: homeData.pageName,
      pageId: homeData.pageId,
      contents: {
        topHeading: homeData.topHeading,
        heading1: homeData.heading1,
        heading2: homeData.heading2,
        heading3: homeData.heading3,
        para1: homeData.para1,
        para2: homeData.para2,
        para3: homeData.para3,
      },
    };

    await updateContent(payload, {
      onSuccess: async (res) => {
        toast.success("Home Contents Updated!");
        await fetchAllPageData();
      },
      onFailed: (err) => {
        toast.error("Something went Wrong!");
        console.error(err);
      },
    });
  };

  const handlePublishedStatus = async () => {
    setPublished(!published);
    console.log(homeInfo?._id);

    await publushedStatusUpdate(homeInfo?._id, {
      onSuccess: async (res) => {
        console.log(res);
        let message =
          published == true
            ? "Page unpublished successfully!"
            : "Page published successfully!";
        toast.success(message);
        await fetchAllPageData();
      },
      onFailed: (err) => {
        let errMsg = err?.errormessage ? err.errormessage : err.message;
        console.log(err);
        toast.error(errMsg);
      },
    });
  };

  return (
    <div>
      <div className="container-fluid last-box" style={{ background: "#fff" }}>
        {/* {!settingsLoading && (
          <div className="pulished_">
            <label htmlFor="" className="me-2">
              <b> Disable this page? </b>
            </label>
            <Switch checked={published} onChange={handlePublishedStatus} />
          </div>
        )} */}
        <h2 className="foot-head text-center">
          <input
            type="text"
            className="settings_home"
            name="topHeading"
            value={homeData.topHeading}
            onChange={handleChange}
          />
        </h2>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="foot-content">
                <input
                  type="text"
                  className="sec_1"
                  name="heading1"
                  value={homeData.heading1}
                  onChange={handleChange}
                />
                <textarea
                  className="sec_1_para"
                  name="para1"
                  value={homeData.para1}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="foot-content">
                <input
                  type="text"
                  className="sec_1"
                  name="heading2"
                  value={homeData.heading2}
                  onChange={handleChange}
                />
                <textarea
                  className="sec_1_para"
                  placeholder=""
                  name="para2"
                  value={homeData.para2}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="foot-content">
                <input
                  type="text"
                  className="sec_1"
                  name="heading3"
                  value={homeData.heading3}
                  onChange={handleChange}
                />
                <textarea
                  className="sec_1_para"
                  placeholder=""
                  name="para3"
                  value={homeData.para3}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="mx-auto text-center mb-3">
            <button
              className="update_Btn"
              onClick={handleHomePage}
              type="button"
              disabled={settingsLoading}
            >
              {!settingsLoading ? (
                "Update"
              ) : (
                <PulseLoader color="#fff" size={10} />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
