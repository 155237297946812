import React, { useContext, useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import * as Ci from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import "./ComplaintCategory.css";
import * as Bs from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import { AppContext } from "../../Context/AppContext";
import ReactPaginate from "react-paginate";

function ComplaintCategory() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const {
    categoryLoading,
    complaintCategoryList,
    getComplaintCategory,
    deleteComplaintCategory,
  } = useContext(AppContext);
  const totalItems = complaintCategoryList.length;

  useEffect(() => {
    setCurrentPage(0); // Reset current page when the complaintCategoryList changes
  }, [complaintCategoryList]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = complaintCategoryList.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const DeleteCategory = async (id) => {
    if (!id) {
      console.error("Something Went Wrong");
      return;
    }
    await deleteComplaintCategory(id, {
      onSuccess: async (res) => {
        toast.success("Review Category Deleted Successfully!");

        await getComplaintCategory();
        setTimeout(() => {});
      },
      onFailed: (err) => {
        toast.error("Something went Wrong!");
        console.error(err);
      },
    });
  };
  return (
    <div className="container-fluid background">
      <Toaster position="top-center" reverseOrder={false} />
      <Header />
      <div className="mains">
        <div className="table-title data">
          <div className="flex-cont">
            <div className="flex-start m-1">
              <h4 className="dash-head text-center">
                List of Review Categories
              </h4>
            </div>

            <div className="flex-end">
              <button
                type="button"
                className="btn btn-add"
                onClick={() => navigate("/addcomplaintcategory")}
              >
                Add
              </button>
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="main-wrapper">
            <div className="mt-3">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12 content">
                  <div className="card dash_table table-widget">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th scope="col">Category Name</th>
                              <th scope="col">Description</th>
                              <th scope="col" className="text-center">
                                Edit
                              </th>
                              <th scope="col" className="text-center">
                                Delete
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((category, index) => {
                              return (
                                <tr key={category.id}>
                                  <th>{index + 1}</th>
                                  <td>{category.complaintCategoryName}</td>
                                  <td>
                                    {category.complaintCategoryDescription}
                                  </td>
                                  <td className="text-center">
                                    <button
                                      className="action_btn"
                                      onClick={() =>
                                        navigate(
                                          `/editcomplaintcategory/${category._id}`
                                        )
                                      }
                                    >
                                      <i>
                                        <Ci.CiEdit />
                                      </i>
                                    </button>
                                  </td>
                                  <td className="text-center">
                                    <button
                                      className="action_btn"
                                      onClick={() =>
                                        DeleteCategory(category._id)
                                      }
                                    >
                                      <i>
                                        <Bs.BsTrash />
                                      </i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="pagination">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      onPageChange={handlePageChange}
                      pageCount={Math.ceil(totalItems / itemsPerPage)}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={3}
                      containerClassName={"pagination"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      disabledClassName={"disabled"}
                      activeClassName={"paging__link--active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComplaintCategory;
