import React from 'react';
import * as Bs from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

function Backbutton() {
    const navigate= useNavigate();
  return (
    <div>
        <button className="back-btn" onClick={() => navigate(-1)}>
              <Bs.BsArrowLeft />
            </button>
    </div>
  )
}

export default Backbutton
