import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import toast, { Toaster } from "react-hot-toast";
import { AppContext } from "../../Context/AppContext";
import { PulseLoader } from "react-spinners";
import Backbutton from "../../Components/Header/Backbutton";

function AddBusinessCategory() {
  const navigate = useNavigate();
  const {
    businessCategoryLoading,
    addBusinessCategory,
    getAllBusinesscategory,
  } = useContext(AppContext);
  const [initialcategory, setInitialCategory] = useState({
    businessCategoryName: "",
    image: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (initialcategory.businessCategoryName.length == 0) return;

    const formData = new FormData();
    formData.append(
      "businessCategoryName",
      initialcategory.businessCategoryName
    );
    if (initialcategory.image !== null) {
      formData.append("image", initialcategory.image);
    }

    await addBusinessCategory(formData, {
      onSuccess: async (res) => {
        toast.success(res.message);
        await getAllBusinesscategory();

        setTimeout(() => {
          navigate("/businesscategory");
        }, 1000);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };

  return (
    <div className="container-fluid background">
      <Toaster position="top-center" />
      <Header />
      <div className="mains">
        <div class="edit-title data mb-3">
          <div class="start-edit">
            <Backbutton />
          </div>
          <div class="center-edit text-center">
            <h4 class="dash-head text-center" >Add Business Category</h4>
          </div>
        </div>
        <div className="card add-category p-3">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="businessCategoryName" className="form-label">
                Business Category
              </label>
              <input
                type="text"
                className="form-control"
                id="businessCategoryName"
                placeholder="Please fill the business category name"
                value={initialcategory.businessCategoryName}
                onChange={(e) => {
                  setInitialCategory((prev) => ({
                    ...prev,
                    businessCategoryName: e.target.value,
                  }));
                }}
              />
              {initialcategory.businessCategoryName.length == 0 && (
                <span style={{ color: "red" }}>category Name is Required</span>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="image" className="form-label">
                Image Upload
              </label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => {
                  setInitialCategory((prev) => ({
                    ...prev,
                    image: e.target.files[0],
                  }));
                }}
              />
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-danger">
                {businessCategoryLoading ? (
                  <PulseLoader color="#fff" size={10} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddBusinessCategory;
