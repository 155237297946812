import React, { useState, useEffect, useContext } from "react";
import Header from "../../Components/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import useComplaintCategory from "../../Hooks/useComplaintCategory";
import toast, { Toaster } from "react-hot-toast";
import { AppContext } from "../../Context/AppContext";
import { PulseLoader } from "react-spinners";
import Backbutton from "../../Components/Header/Backbutton";

function EditComplaintCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    complaintCategoryList,
    categoryLoading,
    getComplaintCategory,
    addComplaintCategory,
  } = useContext(AppContext);

  const [complaintCategories, setComplaintCategories] = useState({
    complaintCategoryId: "",
    complaintCategoryName: "",
    complaintCategoryDescription: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    findComplaintCategory();
  }, [complaintCategoryList]);

  const findComplaintCategory = () => {
    if (!id) return;

    const complaintCategory = complaintCategoryList.find((x) => x._id == id);

    // if (!complaintCategory) return;
    setComplaintCategories((prev) => ({
      ...prev,
      complaintCategoryId: id,
      complaintCategoryName: complaintCategory.complaintCategoryName,
      complaintCategoryDescription:
        complaintCategory.complaintCategoryDescription,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e;
    setComplaintCategories((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (complaintCategories.complaintCategoryName.length == 0) return;

    const payload = complaintCategories;
    setLoading(true);
    await addComplaintCategory(payload, {
      onSuccess: async (res) => {
        setLoading(false);
        await getComplaintCategory();
        toast.success("Edited Successfully!");

        setTimeout(() => {
          navigate("/complaint-category");
        }, 1000);
      },
      onFailed: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  return (
    <div className="container-fluid background">
      <Toaster position="top-center" />
      <Header />
      <div className="mains">
        <div class="edit-title data mb-3">
          <div class="start-edit">
            <Backbutton />
          </div>
          <div class="center-edit text-center">
            <h4 class="dash-head text-center">Edit Complaint Category</h4>
          </div>
        </div>
        <div className="add-category">
          <div className="card review_card edit_card">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="categoryName" className="form-label">
                  Complaint Category
                </label>
                <input
                  name="complaintCategoryName"
                  type="text"
                  className="form-control"
                  id="categoryName"
                  value={complaintCategories?.complaintCategoryName ?? "N/A"}
                  onChange={(e) => handleChange(e.target)}
                />
                {complaintCategories.complaintCategoryName.length === 0 && (
                  <span style={{ color: "red" }}>
                    Review Category Name is Required!
                  </span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="categoryDescription" className="form-label">
                  Description
                </label>
                <input
                  name="complaintCategoryDescription"
                  type="text"
                  className="form-control"
                  id="categoryDescription"
                  value={
                    complaintCategories?.complaintCategoryDescription ?? "N/A"
                  }
                  onChange={(e) => handleChange(e.target)}
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-danger"
                  disabled={loading}
                >
                  {loading ? <PulseLoader color="#fff" size={10} /> : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditComplaintCategory;
