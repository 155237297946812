import React from "react";
import Login from "../Pages/Login/Login";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Complaints from "../Pages/Complaints/Complaints";
import ViewComplaints from "../Pages/Complaints/ViewComplaints";
import ComplaintCategory from "../Pages/ComplaintCategory/ComplaintCategory";
import AddComplaintCategory from "../Pages/ComplaintCategory/AddComplaintCategory";
import BusinessCategory from "../Pages/BusinessCategory/BusinessCategory";
import EditBusinessCategory from "../Pages/BusinessCategory/EditBusinessCategory";
import EditComplaintCategory from "../Pages/ComplaintCategory/EditComplaintCategory";
import AddBusinessCategory from "../Pages/BusinessCategory/AddBusinessCategory";
import BusinessProfile from "../Pages/BusinessProfiles/BusinessProfile";
import AddBusinessProfile from "../Pages/BusinessProfiles/AddBusinessProfile";
import EditBusinessProfile from "../Pages/BusinessProfiles/EditBusinessProfile";
import Users from "../Pages/Users/Users";
import ViewUser from "../Pages/Users/ViewUser";
import ErrorComponent from "../Components/Error/Error";
import Settings from "../Pages/Settings/Settings";

const AppRoutes = [
  {
    isProtectedAuth: false,
    path: "/login",
    element: <Login />,
  },
  {
    isProtected: true,
    path: "/",
    element: <Dashboard />,
  },
  {
    isProtected: true,
    path: "/complaints",
    element: <Complaints />,
  },
  {
    isProtected: true,
    path: "/view-review/:id",
    element: <ViewComplaints />,
  },
  {
    isProtected: true,
    path: "/complaint-category",
    element: <ComplaintCategory />,
  },
  {
    isProtected: true,
    path: "/addcomplaintcategory",
    element: <AddComplaintCategory />,
  },
  {
    isProtected: true,
    path: "/businesscategory",
    element: <BusinessCategory />,
  },
  {
    isProtected: true,
    path: "/editbusinesscategory/:id",
    element: <EditBusinessCategory />,
  },
  {
    isProtected: true,
    path: "/editcomplaintcategory/:id",
    element: <EditComplaintCategory />,
  },
  {
    isProtected: true,
    path: "/addbusinesscategory",
    element: <AddBusinessCategory />,
  },
  {
    isProtected: true,
    path: "/businessprofile",
    element: <BusinessProfile />,
  },
  {
    isProtected: true,
    path: "/addbusinessprofile",
    element: <AddBusinessProfile />,
  },
  {
    isProtected: true,
    path: "/editbusinessprofile/:id",
    element: <EditBusinessProfile />,
  },
  {
    isProtected: true,
    path: "/viewuser/:id",
    element: <ViewUser />,
  },
  {
    isProtected: true,
    path: "/users",
    element: <Users />,
  },
  {
    isProtected: true,
    path: "/settings",
    element: <Settings />,
  },
];

export default AppRoutes;
