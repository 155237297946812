import React, { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../../src/Helpers/BaseClient";
import CustomErrors from "../Constants/Errors";

const useAuth = () => {
  const [authLoading, setAuthLoading] = useState(false);
  const [cookieData, setCookieData] = useState(null);
  const [cookieError, setCookieError] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);

  const adminLogin = async (payload, { onSuccess, onFailed }) => {
    try {
      setAuthLoading(true);
      await BaseClient.post(APIEndpoints.adminAuth, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setAuthLoading(false);
    }
  };

  const getCookieData = async () => {
    try {
      await BaseClient.get(APIEndpoints.getCookieData, {
        withCredentials: true,
        onSuccess: (res) => {
          if (res?.data != null && res?.data != "") {
            setCookieData(res.data);
          }
        },
        onFailed: (err) => {
          console.error("Session Error", err);
          setCookieError(CustomErrors.SESSION_ERROR);
        },
      });
    } finally {
      setPageLoading(false);
    }
  };

  const deleteCookieData = async ({ onLogoutSuccess }) => {
    try {
      setAuthLoading(true);
      await BaseClient.delete(APIEndpoints.deleteCookieData, {
        withCredentials: true,
        onSuccess: (res) => {
          setCookieData(null);
          onLogoutSuccess && onLogoutSuccess();
        },
        onFailed: (err) => {
          console.error("Session Logout Error", err);
        },
      });
    } finally {
      setAuthLoading(false);
    }
  };

  return {
    adminLogin,
    authLoading,
    getCookieData,
    deleteCookieData,
    setCookieData,
    cookieData,
    cookieError,
    setCookieError,
    pageLoading,
  };
};

export default useAuth;
